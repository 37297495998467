import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

export function fetchEntities (jwt, pagingData) {
  return axios.get(`${API_URL}/entities`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: pagingData
  })
}

export function fetchSourceGroups (jwt, pagingData) {
  return axios.get(`${API_URL}/source-groups`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: pagingData
  })
}

export function addSourceGroup (jwt, sourceGroup) {
  return axios.post(`${API_URL}/source-groups`, sourceGroup, {
    headers: { Authorization: `Bearer: ${jwt}` }
  })
}

export function deleteSourceGroup (jwt, groupId) {
  return axios.delete(`${API_URL}/source-groups/${groupId}`, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function getCategoryFields (category) {
  return axios.get(`${API_URL}/fields/${category}`)
}

export function suggestNames (namePart) {
  return axios.get(`${API_URL}/names/${namePart}`)
}

export function getEntitiesNumber (jwt, requestParams) {
  return axios.get(`${API_URL}/entities/count`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function exportDashboardCsv (jwt, requestParams) {
  return axios.get(`${API_URL}/export/dashboard`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams,
    responseType: 'blob'
  })
}

export function getMarketplaceItems () {
  return axios.get(`${API_URL}/marketplace-items`)
}

export function addEntity (jwt, entity) {
  return axios.post(`${API_URL}/entities`, entity, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function fetchEntity (jwt, entityId) {
  return axios.get(`${API_URL}/entities/${entityId}`, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function fetchEntityNoAuth (entityId) {
  return axios.get(`${API_URL}/entities/${entityId}/no-auth`)
}

export function fetchSourceGroup (jwt, groupId) {
  return axios.get(`${API_URL}/source-groups/${groupId}`, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function deleteEntity (jwt, entityId) {
  return axios.delete(`${API_URL}/entities/${entityId}`, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function updateEntity (jwt, entityId, entity) {
  return axios.put(`${API_URL}/entities/${entityId}`, entity, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function updateSourceGroup (jwt, groupId, groupData) {
  return axios.put(`${API_URL}/source-groups/${groupId}`, groupData, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function getWordCloudNoAuth (eventId) {
  return axios.get(`${API_URL}/wordcloud/${eventId}/no-auth`)
}

export function getWordCloud (jwt, eventId) {
  return axios.get(`${API_URL}/wordcloud/${eventId}`, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function saveSettings (jwt, settings) {
  return axios.post(`${API_URL}/user_settings`, settings, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function getSettings (jwt) {
  return axios.get(`${API_URL}/user_settings`, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function getEventArticles (jwt, eventId) {
  return axios.get(`${API_URL}/events/${eventId}/articles`, {
    headers: { Authorization: `Bearer: ${jwt}` }
  })
}

export function getEventArticlesNoAuth (eventId) {
  return axios.get(`${API_URL}/events/${eventId}/articles/no-auth`)
}

export function getEvents (jwt, requestParams) {
  return axios.get(`${API_URL}/events`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function getEventsNoAuth (requestParams) {
  return axios.get(`${API_URL}/events/no-auth`, {
    params: requestParams
  })
}

export function getSentimentItems (jwt, requestParams) {
  return axios.get(`${API_URL}/sentiment-items`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function getSentimentItemsNoAuth (requestParams) {
  return axios.get(`${API_URL}/sentiment-items/no-auth`, {
    params: requestParams
  })
}

export function chartArticlesTimeline (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/articles-timeline`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartCommentsTimeline (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/comments-timeline`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartTweetsTimeline (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/tweets-timeline`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartArticlesPerType (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/articles-per-type`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartCommentsPerType (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/comments-per-type`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartArticlesPerDomain (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/articles-per-domain`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartCommentsPerDomain (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/comments-per-domain`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartAllVsEntityNoAuth (requestParams) {
  return axios.get(`${API_URL}/chart/all-vs-entity/no-auth`, {
    params: requestParams
  })
}

export function chartAllVsEntity (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/all-vs-entity`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartSentimentOverTime (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/sentiment-over-time`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartSentimentOverTimeNoAuth (requestParams) {
  return axios.get(`${API_URL}/chart/sentiment-over-time/no-auth`, {
    params: requestParams
  })
}

export function chartFieldAggregation (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/field-aggregation`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartFieldAggregationNoAuth (requestParams) {
  return axios.get(`${API_URL}/chart/field-aggregation/no-auth`, {
    params: requestParams
  })
}

export function chartTermsAggregationNoAuth (requestParams) {
  return axios.get(`${API_URL}/chart/terms-aggregation/no-auth`, {
    params: requestParams
  })
}

export function chartTermsAggregation (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/terms-aggregation`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartItemsCount (jwt, requestParams) {
  return axios.get(`${API_URL}/chart/items-count`, {
    headers: { Authorization: `Bearer: ${jwt}` },
    params: requestParams
  })
}

export function chartItemsCountNoAuth (requestParams) {
  return axios.get(`${API_URL}/chart/items-count/no-auth`, {
    params: requestParams
  })
}

export function authenticate (userData) {
  return axios.post(`${API_URL}/login`, userData)
}

export function refreshJwt (jwt) {
  return axios.post(`${API_URL}/refresh-token`, {}, { headers: { Authorization: `Bearer: ${jwt}` } })
}

export function register (userData) {
  return axios.post(`${API_URL}/register`, userData)
}
