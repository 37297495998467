<template>
    <multiselect v-model="keywordsValue"
                 :options="options"
                 :multiple="true"
                 :taggable="true"
                 :close-on-select="false"
                 placeholder="Enter new value"
                 @tag="addTag">
    </multiselect>
    <!--Multiselect bootstrap theme: https://github.com/shentao/vue-multiselect/issues/718-->
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'KeywordInput',
  components: { Multiselect },
  props: {
    value: Array,
    suggestionsIndex: String,
    suggestionsField: String
  },
  data () {
    return {
      options: []
    }
  },
  methods: {
    addTag (tag) {
      // Trim the tag (remove whitespace from start & end)
      const trimmedTag = tag.trim()
      let tagsList = []

      // Check if we should split the tag
      if (trimmedTag.indexOf(this.$store.getters.keywordSeparator)) {
        // Tag should be split
        tagsList = trimmedTag.split(this.$store.getters.keywordSeparator)
      } else {
        // Add the single tag to the list of tags to add
        tagsList = [trimmedTag]
      }

      // Add tags to the multiselect
      this._.each(tagsList, tag => {
        // Add this tag to the selected options, if it doesn't exist already in the selected options
        if (this.keywordsValue.indexOf(tag) <= -1) {
          this.keywordsValue.push(tag)
          this.options.push(tag)
        }
      })
    }
  },
  mounted () {
    // Get suggested keywords if we have the required parameters
    if (!this._.isUndefined(this.suggestionsIndex) && !this._.isUndefined(this.suggestionsField)) {
      // Get date range for last week
      const n = new Date()
      const start = new Date(n.getFullYear(), n.getMonth(), n.getDate() - 13)
      const end = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1)

      // Create request parameters
      const requestData = {
        date_start: start,
        date_end: end,
        index: this.suggestionsIndex,
        field: this.suggestionsField,
        size: 400
      }

      // Make request to get suggested keywords
      this.$store.dispatch('chartTermsAggregation', requestData)
        .then(({ data }) => {
          // Add the suggestions to the widget
          this.options.push(...this._.map(data.terms, 0))
        })
    }
  },
  computed: {
    keywordsValue: {
      // Source: https://zaengle.com/blog/using-v-model-on-nested-vue-components
      get () {
        return this.value
      },
      set (keywordsValue) {
        this.$emit('input', keywordsValue)
      }
    }
  }
}
</script>

<style scoped>

</style>
