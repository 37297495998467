<template>
    <b-card :title="title">
        <multiselect v-model="groupsValue"
                     label="name"
                     track-by="id"
                     :multiple="true"
                     :options="sourceGroups">
        </multiselect>
    </b-card>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'SourceGroupSelector',
  components: { Multiselect },
  props: {
    title: String,
    value: Array
  },
  data () {
    return {
      sourceGroups: []
    }
  },
  computed: {
    groupsValue: {
      // Source: https://zaengle.com/blog/using-v-model-on-nested-vue-components
      get () {
        return this.value
      },
      set (groupsValue) {
        this.$emit('input', groupsValue)
      }
    }
  },
  mounted () {
    this.$store.dispatch('fetchSourceGroups', {})
      .then(({ data }) => {
        this.sourceGroups = data.groups.map(item => {
          const { _source, _id } = item
          return {
            // sources: _source.sources,
            id: _id,
            name: _source.name
          }
        })
      })
  }
}
</script>

<style scoped>

</style>
