import Vue from 'vue'
import Vuex from 'vuex'
import autorefresh from 'jwt-autorefresh'
import {
  addEntity,
  addSourceGroup,
  authenticate,
  chartAllVsEntity,
  chartAllVsEntityNoAuth,
  chartArticlesPerDomain,
  chartArticlesPerType,
  chartArticlesTimeline,
  chartCommentsPerDomain,
  chartCommentsPerType,
  chartCommentsTimeline,
  chartFieldAggregation,
  chartFieldAggregationNoAuth,
  chartItemsCount,
  chartItemsCountNoAuth,
  chartSentimentOverTime,
  chartSentimentOverTimeNoAuth,
  chartTermsAggregation,
  chartTermsAggregationNoAuth,
  chartTweetsTimeline,
  deleteEntity,
  deleteSourceGroup,
  exportDashboardCsv,
  fetchEntities,
  fetchEntity,
  fetchEntityNoAuth,
  fetchSourceGroup,
  fetchSourceGroups,
  getCategoryFields,
  getEntitiesNumber,
  getEventArticles,
  getEventArticlesNoAuth,
  getEvents,
  getEventsNoAuth,
  getMarketplaceItems,
  getSentimentItems,
  getSentimentItemsNoAuth,
  getSettings,
  getWordCloud,
  getWordCloudNoAuth,
  refreshJwt,
  register,
  saveSettings,
  suggestNames,
  updateEntity,
  updateSourceGroup
} from '../api'
import { EventBus, isValidJwt, usernameFromJwt } from '../utils'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    // single source of data
    userData: {},
    jwt: '',
    userSettings: {},
    cancelAutoRefresh: () => {
    },
    entityTypes: [{
      type: 'Party',
      icon: 'fa-users'
    }, {
      type: 'Person',
      icon: 'fa-user'
    }, {
      type: 'Organization',
      icon: 'fa-building'
    }, {
      type: 'Location',
      icon: 'fa-map-pin'
    }, {
      type: 'Other',
      icon: 'fa-ellipsis-h'
    }],
    entityFields: [
      {
        key: 'name',
        label: 'Name',
        type: 'text',
        hint: 'Please enter first name, middle name (if applicable) and then last name.',
        hintFilter: [
          'Person'
        ],
        showInDetails: false,
        allowEditing: true
      },
      {
        key: 'category',
        label: 'Category',
        type: 'text',
        showInDetails: false,
        allowEditing: true
      },
      {
        key: 'keywords',
        label: 'Keywords',
        type: 'list',
        suggestionsIndex: 'articles',
        suggestionsField: 'tags',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'excluded_keywords',
        label: 'Excluded Keywords',
        type: 'list',
        suggestionsIndex: 'articles',
        suggestionsField: 'tags',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'hashtags',
        label: 'Hashtags',
        type: 'list',
        suggestionsIndex: 'tweets',
        suggestionsField: 'entities.hashtags.text',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'mentions',
        label: 'Mentions',
        type: 'list',
        suggestionsIndex: 'tweets',
        suggestionsField: 'entities.user_mentions.screen_name',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'email',
        label: 'Email Address',
        type: 'text',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'homepage',
        label: 'Homepage URL',
        type: 'url',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'profile_image',
        label: 'Profile Image URL',
        type: 'url',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'wikidata_name',
        label: 'Wikidata Name',
        type: 'text',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'twitter_username',
        label: 'Twitter Username',
        type: 'text',
        hint: 'Please enter only the username of the account, e.g. username or @username.',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'instagram_username',
        label: 'Instagram Username',
        type: 'text',
        hint: 'Please enter only the username of the account, e.g. username or @username.',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'wikidata_url',
        label: 'Wikidata URL',
        type: 'url',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'wikipedia_url',
        label: 'Wikipedia URL',
        type: 'url',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'facebook_page',
        label: 'Facebook Page',
        type: 'url',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'youtube_channel',
        label: 'YouTube Channel',
        type: 'url',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'linkedin_page',
        label: 'LinkedIn Page',
        type: 'url',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'public',
        label: 'Public',
        type: 'boolean',
        showInDetails: true,
        allowEditing: true
      },
      {
        key: 'owners',
        label: 'Owners',
        type: 'list',
        showInDetails: false,
        allowEditing: false
      },
      {
        key: 'created_at',
        label: 'Created at',
        type: 'timestamp',
        showInDetails: true,
        allowEditing: false
      },
      {
        key: 'updated_at',
        label: 'Updated at',
        type: 'timestamp',
        showInDetails: true,
        allowEditing: false
      },
      {
        key: 'created_by',
        label: 'Created by',
        type: 'text',
        showInDetails: true,
        allowEditing: false
      },
      {
        key: 'updated_by',
        label: 'Updated by',
        type: 'text',
        showInDetails: true,
        allowEditing: false
      }
    ]
  },
  actions: {
    // asynchronous operations
    login (context, userData) {
      // Authenticate the user
      return authenticate(userData)
        .then(response => {
          // Save the user data to the store
          context.commit('setUserData', {
            userData: {
              username: usernameFromJwt(response.data.token),
              password: userData.password
            }
          })

          // Get whether or not this user can export data from the response
          // noinspection JSUnresolvedVariable
          localStorage.allowExport = (Vue._.has(response.data, 'allow_export') &&
            response.data.allow_export === true)

          // Set the JWT
          context.commit('setJwtToken', {
            jwt: {
              token: response.data.token
            }
          })

          // Start JWT autorefresh
          context.commit('startAutoRefresh')

          // Get settings from server and return promise
          return this.dispatch('setSettingsFromServer')
        })
        .catch(error => {
          console.log('Error Authenticating: ', error)
          EventBus.$emit('failed-authentication', error)
        })
    },
    loginByJwt (context, data) {
      const tokenStr = data.token
      const username = data.username

      // Check if JWT token is valid and set it
      if (isValidJwt(tokenStr)) {
        // Set the token
        context.commit('setJwtToken', {
          jwt: { token: tokenStr }
        })

        // Set userdata
        context.commit('setUserData', {
          userData: {
            username: username
          }
        })

        // Start JWT autorefresh
        context.commit('startAutoRefresh')

        // Get settings from server and return promise
        return this.dispatch('setSettingsFromServer')
      }

      return false
    },
    logout (context) {
      context.commit('setUserData', {})

      // Set JWT token to an empty token
      context.commit('setJwtToken', { jwt: '' })

      // Cancel JWT autorefresh
      context.state.cancelAutoRefresh()
    },
    refresh (context) {
      return refreshJwt(context.state.jwt.token)
        .then(({ data }) => {
          // noinspection JSUnresolvedVariable
          const newToken = data.access_token

          localStorage.token = newToken

          context.dispatch('loginByJwt', {
            token: newToken,
            username: localStorage.username
          })

          // Resolve promise with the new token, as the jwt-autorefresh library wants
          return newToken
        })
    },
    register (context, userData) {
      context.commit('setUserData', { userData })
      return register(userData)
        .catch(error => {
          console.log('Error signing up: ', error)
          EventBus.$emit('failed-registering', error)
        })
    },
    fetchEntities (context, pagingData) {
      return fetchEntities(context.state.jwt.token, pagingData)
    },
    fetchSourceGroups (context, pagingData) {
      return fetchSourceGroups(context.state.jwt.token, pagingData)
    },
    getEntitiesNumber (context, requestParams) {
      return getEntitiesNumber(context.state.jwt.token, requestParams)
    },
    exportDashboardCsv (context, requestParams) {
      return exportDashboardCsv(context.state.jwt.token, requestParams)
    },
    getMarketplaceItems () {
      return getMarketplaceItems()
    },
    getSentimentItems (context, requestParams) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API that doesn't require authentication (but doesn't filter by entity)
        return getSentimentItemsNoAuth(requestParams)
      } else {
        return getSentimentItems(context.state.jwt.token, requestParams)
      }
    },
    getCategoryFields (context, category) {
      return getCategoryFields(category)
    },
    chartOverviewTimeline (context, properties) {
      // Call the appropriate API method based on the given type
      switch (properties.type) {
        case 'comments':
          return chartCommentsTimeline(context.state.jwt.token, properties.params)
        case 'articles':
          return chartArticlesTimeline(context.state.jwt.token, properties.params)
        case 'tweets':
          return chartTweetsTimeline(context.state.jwt.token, properties.params)
      }
    },
    chartPiePerType (context, properties) {
      // Call the appropriate API method based on the given type
      switch (properties.type) {
        case 'comments':
          return chartCommentsPerType(context.state.jwt.token, properties.params)
        case 'articles':
          return chartArticlesPerType(context.state.jwt.token, properties.params)
      }
    },
    chartCountPerDomain (context, properties) {
      // Call the appropriate API method based on the given type
      switch (properties.type) {
        case 'comments':
          return chartCommentsPerDomain(context.state.jwt.token, properties.params)
        case 'articles':
          return chartArticlesPerDomain(context.state.jwt.token, properties.params)
      }
    },
    chartAllVsEntity (context, requestParams) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API that doesn't require authentication (but doesn't filter by entity)
        return chartAllVsEntityNoAuth(requestParams)
      } else {
        return chartAllVsEntity(context.state.jwt.token, requestParams)
      }
    },
    chartSentimentOverTime (context, requestParams) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API that doesn't require authentication (but only allows specific entities)
        return chartSentimentOverTimeNoAuth(requestParams)
      } else {
        return chartSentimentOverTime(context.state.jwt.token, requestParams)
      }
    },
    chartFieldAggregation (context, requestParams) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API that doesn't require authentication (but doesn't filter by entity)
        return chartFieldAggregationNoAuth(requestParams)
      } else {
        return chartFieldAggregation(context.state.jwt.token, requestParams)
      }
    },
    chartTermsAggregation (context, requestParams) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API endpoint that doesn't require authentication
        return chartTermsAggregationNoAuth(requestParams)
      } else {
        return chartTermsAggregation(context.state.jwt.token, requestParams)
      }
    },
    chartItemsCount (context, requestParams) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API endpoint that doesn't require authentication
        return chartItemsCountNoAuth(requestParams)
      } else {
        return chartItemsCount(context.state.jwt.token, requestParams)
      }
    },
    suggestNames (context, namePart) {
      return suggestNames(namePart)
    },
    addSourceGroup (context, sourceGroup) {
      return addSourceGroup(context.state.jwt.token, sourceGroup)
    },
    fetchEntity (context, entityId) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API endpoint that doesn't require authentication
        return fetchEntityNoAuth(entityId)
      } else {
        return fetchEntity(context.state.jwt.token, entityId)
      }
    },
    fetchSourceGroup (context, groupId) {
      return fetchSourceGroup(context.state.jwt.token, groupId)
    },
    addEntity (context, entity) {
      return addEntity(context.state.jwt.token, entity)
    },
    deleteEntity (context, entityId) {
      return deleteEntity(context.state.jwt.token, entityId)
    },
    deleteSourceGroup (context, groupId) {
      return deleteSourceGroup(context.state.jwt.token, groupId)
    },
    getEventArticles (context, eventId) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API endpoint that doesn't require authentication
        return getEventArticlesNoAuth(eventId)
      } else {
        return getEventArticles(context.state.jwt.token, eventId)
      }
    },
    getEvents (context, requestParams) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API endpoint that doesn't require authentication
        return getEventsNoAuth(requestParams)
      } else {
        return getEvents(context.state.jwt.token, requestParams)
      }
    },
    updateEntity (context, entity) {
      return updateEntity(context.state.jwt.token, entity.id, entity.data)
    },
    updateSourceGroup (context, group) {
      return updateSourceGroup(context.state.jwt.token, group.id, group.data)
    },
    getWordCloud (context, eventId) {
      if (Vue._.isUndefined(context.state.jwt.token)) {
        // Call API endpoint that doesn't require authentication
        return getWordCloudNoAuth(eventId)
      } else {
        return getWordCloud(context.state.jwt.token, eventId)
      }
    },
    saveSettings (context, settings) {
      return saveSettings(context.state.jwt.token, settings)
    },
    getSettings (context) {
      return getSettings(context.state.jwt.token)
    },
    setSettingsFromServer (context) {
      // Get settings for the user from the server and save them to the store
      return getSettings(context.state.jwt.token)
        .then(({ data }) => {
          // Check that the user had settings saved in Elasticsearch
          if (data.found) {
            // Save the settings to vuex store
            context.commit('setUserSettings', {
              userSettings: data._source
            })
          } else {
            // No settings found for this user, set defaults
            context.commit('setDefaultUserSettings')
          }
        })
    }
  },
  mutations: {
    // isolated data mutations
    setUserData (state, payload) {
      // Get username and set it in localStorage
      let username = ''
      if (typeof payload.userData !== 'undefined') {
        username = payload.userData.username
      }
      localStorage.username = username

      // Set userData
      state.userData = payload.userData
    },
    setJwtToken (state, payload) {
      localStorage.token = payload.jwt.token
      state.jwt = payload.jwt
    },
    setUserSettings (state, payload) {
      state.userSettings = payload.userSettings
    },
    setDefaultUserSettings (state) {
      // Set the default settings for this user
      state.userSettings = {
        pageSize: 10
      }
    },
    startAutoRefresh (state) {
      const leadSeconds = this.getters.leadSeconds
      const refresh = () => {
        return this.dispatch('refresh')
      }

      const start = autorefresh({ refresh, leadSeconds })
      state.cancelAutoRefresh()
      state.cancelAutoRefresh = start(state.jwt.token)
    }
  },
  getters: {
    // reusable data accessors
    keywordSeparator () {
      return '\u241e'
    },
    isAuthenticated (state) {
      return isValidJwt(state.jwt.token)
    },
    userName (state) {
      return state.userData.username
    },
    userSettings (state) {
      return state.userSettings
    },
    cancelAutoRefresh (state) {
      return state.cancelAutoRefresh
    },
    leadSeconds () {
      // Generate random additional seconds (up to 30 in this case) to append to the lead time to ensure multiple
      // clients don't schedule simultaneous refresh
      const jitter = Math.floor(Math.random() * 30)

      // Schedule autorefresh to occur 60 to 90 seconds prior to token expiration
      return 60 + jitter
    },
    getPublicEntitiesValue () {
      // If the "publicEntities" value exists in localStorage, get it and parse it as a boolean. Otherwise return true.
      return (Vue._.isUndefined(localStorage.publicEntities)) ? true : (localStorage.publicEntities === 'true')
    },
    entityFieldsEditor (state) {
      // Return entity fields formatted for the entity editor
      return Vue._.map(state.entityFields, function (item) {
        return {
          key: item.key,
          label: item.label,
          type: item.type,
          suggestionsIndex: item.suggestionsIndex,
          suggestionsField: item.suggestionsField,
          hint: item.hint,
          hintFilter: item.hintFilter
        }
      })
    },
    lastWeekDateRange () {
      // Return a date range object for the last week
      return {
        start: new Date(new Date().setDate(new Date().getDate() - 7)),
        end: new Date()
      }
    },
    hiddenEntityFieldsEditor (state) {
      // Return the names of the fields which shouldn't be shown to the user when editing an entity
      const items = Vue._.filter(state.entityFields, {
        allowEditing: false
      })

      // Return only the keys
      return Vue._.map(items, 'key')
    },
    entityFieldsViewer (state) {
      // Return entity fields which should be shown in the entity viewer (modal)
      return Vue._.filter(state.entityFields, {
        showInDetails: true
      }).map(function (item) {
        return {
          key: item.key,
          label: item.label,
          type: item.type
        }
      })
    },
    entityTypes (state) {
      // Map the entity types in order to add extra properties to them
      return Vue._.map(state.entityTypes, function (item) {
        // Add empty items list, currentPage and entitiesCount properties to each entity type
        return Vue._.assign({
          items: [],
          currentPage: 1,
          entitiesCount: 0
        }, item)
      })
    },
    entityTypeNames (state) {
      // Return the names of the entity types
      return Vue._.map(state.entityTypes, 'type')
    }
  }
})

export default store
