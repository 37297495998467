<template>
    <div>
        <!--Dashboard title-->
        <b-row>
            <b-col>
                <!--Dashboard title-->
                <h1 v-if="entityName">{{ $t('dashboard.title') + ' ' + entityName }}</h1>

                <!--Loading spinner-->
                <h1 v-if="!entityName"><i class="fas fa-spinner fa-pulse"></i></h1>
            </b-col>
        </b-row>

        <!--Date picker-->
        <b-row>
            <b-col offset="2" cols="8">
                <date-range-selector @range-selected="onDateSelected"
                                     :initial-range="selectedDate">
                </date-range-selector>
            </b-col>
        </b-row>

        <!--Source group selector-->
        <b-row v-if="$store.getters.isAuthenticated">
            <b-col>
                <source-group-selector :title="$t('dashboard.include_filter')"
                                       v-model="includedGroups">
                </source-group-selector>
            </b-col>
            <b-col>
                <source-group-selector :title="$t('dashboard.exclude_filter')"
                                       v-model="excludedGroups">
                </source-group-selector>
            </b-col>
        </b-row>

        <!--Tabs-->
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-tabs :lazy="true" card>
                        <!--Summary tab-->
                        <b-tab :title="$t('dashboard.tabs.summary')" active>
                            <!--Number of articles/comments/tweets counts-->
                            <b-row class="counts-row">
                                <b-col>
                                    <!--Number of articles count-->
                                    <metric-display :entity-id="entityId"
                                                    :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_articles')"
                                                    :filters="filters"
                                                    icon="fas fa-newspaper"
                                                    type="article"/>
                                </b-col>

                                <b-col>
                                    <!--Number of comments count-->
                                    <metric-display :entity-id="entityId"
                                                    :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_comments')"
                                                    :filters="filters"
                                                    icon="fas fa-comments"
                                                    type="comment"/>
                                </b-col>

                                <b-col>
                                    <!--Number of tweets count-->
                                    <metric-display :entity-id="entityId"
                                                    :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_tweets')"
                                                    :filters="filters"
                                                    icon="fab fa-twitter"
                                                    type="tweet"/>
                                </b-col>
                            </b-row>

                            <!--Number of domain count widgets-->
                            <b-row class="counts-row">
                                <b-col>
                                    <!--# of domains writing about the entity-->
                                    <unique-count :entity-id="entityId"
                                                  :date-range="selectedDate"
                                                  :filters="filters"
                                                  field="domain"
                                                  :title="$t('dashboard.charts.domains_with_articles')"
                                                  type="article"/>
                                </b-col>

                                <b-col>
                                    <!--# of domains that have comments about the entity-->
                                    <unique-count :entity-id="entityId"
                                                  :date-range="selectedDate"
                                                  :filters="filters"
                                                  field="domain"
                                                  :title="$t('dashboard.charts.domains_with_comments')"
                                                  type="comment"/>
                                </b-col>
                            </b-row>

                            <!--Warning when trying to export data of > 7 days-->
                            <b-row v-if="exportWarning">
                                <b-col cols="8" offset="2">
                                    <!--suppress HtmlUnknownBooleanAttribute -->
                                    <b-alert variant="warning" show dismissible @dismissed="exportWarning = false">
                                        {{ $t('dashboard.messages.export_warning') }}
                                    </b-alert>
                                </b-col>
                            </b-row>

                            <!--Export data button-->
                            <b-row v-if="allowExport">
                                <b-col>
                                    <b-btn @click="exportData" variant="primary">
                                        <i class="fas fa-file-export"></i> {{ $t('dashboard.buttons.export_data') }}
                                    </b-btn>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <hr>
                                    <p class="unfiltered-data text-muted">
                                        {{ $t('dashboard.messages.data_not_for_entity') }}
                                    </p>
                                </b-col>
                            </b-row>

                            <!--Top Twitter hashtags & mentions-->
                            <b-row>
                                <b-col>
                                    <!--Top hashtags-->
                                    <top-terms-bar :date-range="selectedDate"
                                                   :terms-type="$t('dashboard.charts.top_twitter_hashtags_series')"
                                                   :title="$t('dashboard.charts.top_twitter_hashtags')"
                                                   color="#3366AA"
                                                   :size="20"
                                                   index="tweets"
                                                   field="entities.hashtags.text"/>
                                </b-col>

                                <b-col>
                                    <!--Top mentions-->
                                    <top-terms-bar :date-range="selectedDate"
                                                   :terms-type="$t('dashboard.charts.top_twitter_mentions_series')"
                                                   :title="$t('dashboard.charts.top_twitter_mentions')"
                                                   color="#64aa00"
                                                   :size="50"
                                                   index="tweets"
                                                   field="entities.user_mentions.screen_name"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col offset="3" cols="6">
                                    <!--Top article tags-->
                                    <top-terms-bar :date-range="selectedDate"
                                                   :terms-type="$t('dashboard.charts.top_article_tags_series')"
                                                   :title="$t('dashboard.charts.top_article_tags')"
                                                   color="#7B435B"
                                                   :size="50"
                                                   :filters="filters"
                                                   index="articles"
                                                   field="tags"/>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Articles tab-->
                        <b-tab :title="$t('dashboard.tabs.articles')">
                            <b-row>
                                <b-col>
                                    <!--Number of articles count-->
                                    <metric-display :entity-id="entityId"
                                                    :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_articles')"
                                                    :filters="filters"
                                                    icon="fas fa-newspaper"
                                                    type="article"/>
                                </b-col>
                                <b-col>
                                    <!--# of domains writing about the entity-->
                                    <unique-count :entity-id="entityId"
                                                  :date-range="selectedDate"
                                                  field="domain"
                                                  :filters="filters"
                                                  :title="$t('dashboard.charts.unique_domains')"
                                                  type="article"/>
                                </b-col>
                                <b-col>
                                    <!--Avg. polarity-->
                                    <average-polarity :entity-id="entityId"
                                                      :date-range="selectedDate"
                                                      field="domain"
                                                      :filters="filters"
                                                      :title="$t('dashboard.charts.average_polarity')"
                                                      type="article"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Sentiment over time chart-->
                                    <sentiment-over-time :entity-id="entityId"
                                                         :date-range="selectedDate"
                                                         :filters="filters"
                                                         type="article"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Graph of all articles vs ones for the entity-->
                                    <all-vs-entity :entity-id="entityId"
                                                   :date-range="selectedDate"
                                                   :filters="filters"
                                                   type="article"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Top domains bar chart-->
                                    <top-domains-bar :entity-id="entityId"
                                                     :date-range="selectedDate"
                                                     :filters="filters"
                                                     type="article"/>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Comments tab-->
                        <b-tab :title="$t('dashboard.tabs.comments')">
                            <b-row>
                                <b-col offset="4" cols="4">
                                    <!--Number of comments count-->
                                    <metric-display :entity-id="entityId"
                                                    :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_comments')"
                                                    :filters="filters"
                                                    icon="fas fa-comments"
                                                    type="comment"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Sentiment over time chart-->
                                    <sentiment-over-time :entity-id="entityId"
                                                         :date-range="selectedDate"
                                                         :filters="filters"
                                                         type="comment"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Graph of all comments vs ones for the entity-->
                                    <all-vs-entity :entity-id="entityId"
                                                   :date-range="selectedDate"
                                                   :filters="filters"
                                                   type="comment"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Top domains bar chart-->
                                    <top-domains-bar :entity-id="entityId"
                                                     :date-range="selectedDate"
                                                     :filters="filters"
                                                     type="comment"/>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Tweets tab-->
                        <b-tab :title="$t('dashboard.tabs.tweets')">
                            <b-row>
                                <b-col offset="4" cols="4">
                                    <!--Number of tweets count-->
                                    <metric-display :entity-id="entityId"
                                                    :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_tweets')"
                                                    icon="fab fa-twitter"
                                                    type="tweet"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Sentiment over time chart-->
                                    <sentiment-over-time :entity-id="entityId"
                                                         :date-range="selectedDate"
                                                         type="tweet"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Graph of all tweets vs ones for the entity-->
                                    <all-vs-entity :entity-id="entityId"
                                                   :date-range="selectedDate"
                                                   type="tweet"/>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Timeline-->
                        <b-tab :title="$t('dashboard.tabs.timeline')">
                            <event-timeline :entity-id="entityId"
                                            :entity-name="entityName"
                                            :date-range="selectedDate">
                            </event-timeline>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SentimentOverTime from '../../components/charts/sentiment-over-time/SentimentOverTime'
import MetricDisplay from '../../components/charts/MetricDisplay'
import UniqueCount from '../../components/charts/UniqueCount'
import AveragePolarity from '../../components/charts/AveragePolarity'
import AllVsEntity from '../../components/charts/AllVsEntity'
import TopDomainsBar from '../../components/charts/TopDomainsBar'
import DateRangeSelector from '../../components/input/DateRangeSelector'
import TopTermsBar from '../../components/charts/TopTermsBar'
import { saveAs } from 'file-saver'
import EventTimeline from '../../components/EventTimeline'
import SourceGroupSelector from '../../components/input/SourceGroupSelector'

export default {
  name: 'Dashboard',
  components: {
    SourceGroupSelector,
    EventTimeline,
    TopTermsBar,
    DateRangeSelector,
    TopDomainsBar,
    AllVsEntity,
    AveragePolarity,
    UniqueCount,
    MetricDisplay,
    SentimentOverTime
  },
  props: {
    id: String
  },
  computed: {
    filters: function () {
      return {
        include_groups: this._.map(this.includedGroups, 'id'),
        exclude_groups: this._.map(this.excludedGroups, 'id')
      }
    },
    entityId: function () {
      return this.id
    },
    allowExport: function () {
      // Show export button if it's set in the local storage (happens on login) and user is authenticated
      return localStorage.allowExport === 'true' && this.$store.getters.isAuthenticated
    }
  },
  data () {
    return {
      entityName: null,
      selectedDate: this.$store.getters.lastWeekDateRange,
      exportWarning: false,
      showTimeline: false,
      includedGroups: [],
      excludedGroups: []
    }
  },
  methods: {
    onDateSelected: function (dateRange) {
      // Save the date range
      this.selectedDate = dateRange
    },
    exportData: function () {
      // Get required parameters to export the data
      const dateStart = this.selectedDate.start
      const dateEnd = this.selectedDate.end

      // Show warning if range is > a week
      const dateDiffMillis = dateEnd - dateStart
      const dateDiffDays = dateDiffMillis / 1000 / 3600 / 24
      if (dateDiffDays > 7) {
        // Show the warning
        this.exportWarning = true
      }

      // Request data from server
      this.$store.dispatch('exportDashboardCsv', {
        date_start: dateStart,
        date_end: dateEnd,
        entity_id: this.entityId
      })
        .then(({ data }) => {
          // Download the result as a file
          const blob = new Blob([data], { type: 'text/csv' })
          saveAs(blob, 'export.csv')
        })
        .catch(err => {
          // Read the blob and parse its JSON contents to retrieve the error message
          try {
            // Create file reader
            const fr = new FileReader()
            // let vm = this
            fr.onload = function () {
              // When data is read, show an alert with the error message
              const errorData = JSON.parse(this.result.toString())
              alert(errorData.message)
            }

            // Give the blob to the file reader
            fr.readAsText(err.response.data)
          } catch (readError) {
            // Show generic error if we can't read the blob correctly
            alert('Something went wrong while exporting the data!')
          }
        })
    },
    updateEntityName: function () {
      // Fetch the entity data to display its name
      this.$store.dispatch('fetchEntity', this.entityId)
        .then(({ data }) => {
          this.entityName = data.name
        })
    }
  },
  watch: {
    entityId: function () {
      this.updateEntityName()
    }
  },
  mounted () {
    if (!this._.isUndefined(this.entityId)) {
      this.updateEntityName()
    }
  }
}
</script>

<style scoped>
    /* Make counts for comments/articles/tweets fit */
    .counts-row > .col {
        padding-left: 5px;
        padding-right: 5px;
    }

    /* Spaces between rows */
    .row {
        margin-top: 15px;
    }

    .row:first-child {
        margin-top: 0;
    }

    p.unfiltered-data {
        font-size: large;
    }
</style>
