<template>
    <b-row>
        <b-col cols="8" offset="2">
            <b-card :header="$t('dashboard.messages.select_entity_header')" variant="info">
                <p>
                    {{ $t('dashboard.messages.select_entity_text') }}
                    "<router-link to="entities">{{ $t('my_entities.title') }}</router-link>".
                </p>

                <b-btn id="select-entity-btn"
                       variant="primary">
                    <i class="fas fa-user entity-button-icon"></i>
                    {{ $t('dashboard.buttons.select_entity') }}
                </b-btn>

                <!--Entity selection popover-->
                <entity-selection-popover :title="$t('tools.messages.select_entity_for') + ' dashboard'"
                                          @entitySelected="selectEntity"
                                          target="select-entity-btn">
                </entity-selection-popover>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import EntitySelectionPopover from '../../components/EntitySelectionPopover'

export default {
  name: 'SelectEntity',
  components: { EntitySelectionPopover },
  methods: {
    selectEntity: function ({ entity }) {
      const entityId = entity._id

      // Go to the dashboard for this entity
      this.$router.push(`dashboard/${entityId}`)
    }
  }
}
</script>

<style scoped>
    i.entity-button-icon {
        margin-right: 5px;
    }
</style>
