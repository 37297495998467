<template>
    <div class="organize-sources-container">
        <div class="row">
            <div class="col">
                <h1>{{ $t('organize_sources.title') }}</h1>
            </div>
            <div class="col text-right">
                <b-btn variant="success" @click="$router.push('organize-sources/new')">
                    <i class="fas fa-plus"></i>
                    {{ $t('organize_sources.buttons.new_group') }}
                </b-btn>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p>{{ $t('organize_sources.description') }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <b-list-group>
                    <b-list-group-item v-for="group in sourceGroups" :key="group['_id']">
                        <span class="group-name">
                            {{ group['_source']['name'] }}
                        </span>

                        <list-action-buttons class="float-right"
                                             @delete="deleteGroup(group)"
                                             @edit="editGroup(group)"
                                             :dashboard-btn="false"
                                             :can-edit="true">
                        </list-action-buttons>

                        <!--Source sample badges-->
                        <b-jumbotron class="float-right source-badges">
                            <b-badge v-for="source in group['_source']['sources'].slice(0, 3)"
                                     :key="source">
                                {{ source }}
                            </b-badge>

                            <!--"..." badge when there are > 3 sources-->
                            <b-badge v-if="group['_source']['sources'] && group['_source']['sources'].length > 3">
                                &hellip;
                            </b-badge>
                        </b-jumbotron>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>
    </div>
</template>

<script>
import ListActionButtons from '../../components/ListActionButtons'

export default {
  name: 'OrganizeSources',
  components: { ListActionButtons },
  data () {
    return {
      sourceGroups: []
    }
  },
  methods: {
    // Get the source groups of the user
    fetchSourceGroups: function () {
      this.$store.dispatch('fetchSourceGroups', {})
        .then(({ data }) => {
          this.sourceGroups = data.groups
        })
    },
    editGroup: function (group) {
      // Go to group editing page with the ID as a parameter
      this.$router.push({
        name: 'edit-source-group',
        params: {
          id: group._id
        }
      })
    },
    deleteGroup: function (group) {
      // Show alert to confirm deletion
      if (!confirm(this.$t('organize_sources.messages.delete_confirmation') + ' "' + group._source.name + '"?')) {
        return
      }

      // Delete the item and update the list to show the change
      this.$store.dispatch('deleteSourceGroup', group._id)
        .then(this.fetchSourceGroups)
    }
  },
  mounted () {
    this.fetchSourceGroups()
  }
}
</script>

<style scoped>
    .group-name {
        line-height: 31px;
    }

    .organize-sources-container {
        text-align: left;
    }

    .source-badges {
        padding: 2px 2px 3px 2px;
        margin-bottom: 0;
        margin-right: 15px;
    }

    .source-badges > .badge {
        margin-left: 3px;
        background-color: #bfbfbf;
        color: #ffffff;
    }

    .source-badges > .badge:last-child {
        margin-right: 3px;
    }
</style>
