<template>
    <div class="selector-container">
        <b-row>
            <b-col class="multi-select-col">
                <multi-select :options="options"
                              v-model="selectedOptions"
                              :search-options-placeholder="$t('organize_sources.search_sources')"
                              :selected-options-placeholder="$t('organize_sources.search_sources')"
                              :no-options-text="$t('organize_sources.no_sources')"
                              :selected-no-options-text="$t('organize_sources.no_selected_sources')">
                </multi-select>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import MultiSelect from './MultiSelect'

export default {
  name: 'DataSourceSelector',
  components: { MultiSelect },
  props: {
    selectedItems: Object
  },
  data () {
    return {
      options: [],
      selectedOptions: []
    }
  },
  methods: {
    updateOptions: function () {
      // Create request parameters
      const requestData = {
        index: 'articles',
        field: 'netloc',
        size: 1000
      }

      this.$store.dispatch('chartTermsAggregation', requestData)
        .then(({ data }) => {
          // Get the source name of the sources as the options
          this.options = this._.map(data.terms, 0)

          // Set selected options
          this.selectedOptions = this.selectedItems.items
        })
    }
  },
  mounted () {
    this.updateOptions()
  },
  watch: {
    dateRange: function () {
      // Date range changed, update the chart
      this.updateOptions()
    },
    selectedOptions: function () {
      // Update the selected items with only the names of the sources
      this.selectedItems.items = this.selectedOptions
    }
  }
}
</script>

<style scoped>
    .multi-select-col {
        overflow-x: auto;
    }
</style>
