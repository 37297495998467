<template>
    <b-button-group size="sm">
        <!--Dashboard button-->
        <b-btn v-if="dashboardBtn" variant="light" @click.stop="$emit('dashboard')">
            <i class="fas fa-tachometer-alt"></i>
            {{ $t('my_entities.buttons.dashboard') }}
        </b-btn>

        <!--Edit button-->
        <b-btn v-if="canEdit" variant="primary" @click.stop="$emit('edit')">
            <i class="fas fa-pencil-alt"></i>
            {{ $t('my_entities.buttons.edit') }}
        </b-btn>

        <!--Delete button-->
        <b-btn v-if="canEdit" variant="danger" @click.stop="$emit('delete')">
            <i class="fas fa-trash"></i>
        </b-btn>
    </b-button-group>
</template>

<script>
export default {
  name: 'ListActionButtons',
  props: {
    dashboardBtn: Boolean,
    canEdit: Boolean
  }
}
</script>

<style scoped>

</style>
