<template>
    <b-card :title="title"
            bg-variant="light"
            class="single-value-container">
        <h1 :style="numberStyle">{{ value }}</h1>
    </b-card>
</template>

<script>
import BaseSingleValueDisplay from './BaseSingleValueDisplay.vue'

export default {
  name: 'AveragePolarity',
  extends: BaseSingleValueDisplay,
  data () {
    return {
      numberStyle: {
        color: 'black'
      }
    }
  },
  methods: {
    updateValue: function () {
      // Gather request parameters
      const requestParams = {
        entity_id: this.entityId,
        type: this.type,
        date_start: this.dateRange.start,
        date_end: this.dateRange.end,
        field: 'polarity',
        aggregation_type: 'avg'
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestParams, this.filters)
      }

      // Get the average polarity from the API
      this.$store.dispatch('chartFieldAggregation', requestParams)
        .then(({ data }) => {
          this.value = data.value.toFixed(3)
        })
    }
  },
  watch: {
    value: function (newVal) {
      // When the value changes, make it red if negative, green if positive
      this.numberStyle.color = newVal > 0 ? 'green' : 'red'
    }
  }
}
</script>

<style scoped>

</style>
