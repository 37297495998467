<template>
    <div>
        <!--Warning message for errors-->
        <warning-message :error-msg="errorMsg"></warning-message>

        <!--Registration form-->
        <b-row>
            <b-col md="6" offset="3">
                <b-card :title="$t('sign_up.title')">
                    <!--Username-->
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-user"></i>
                            </span>
                        </div>

                        <!--Input field-->
                        <b-input type="text" name="username" v-model="input.username"
                                 :placeholder="$t('sign_up.placeholders.username')"
                                 :state="inputState.username"></b-input>

                        <!--Error feedback-->
                        <div v-if="inputState.username !== null && !inputState.username" role="alert"
                             aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                            {{ $t('sign_up.errors.username') }}
                        </div>
                    </div>

                    <!--Email-->
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-at"></i>
                            </span>
                        </div>

                        <!--Input field-->
                        <b-input type="email" name="email" v-model.trim="input.email"
                                 :placeholder="$t('sign_up.placeholders.email')"
                                 :state="inputState.email"></b-input>

                        <!--Error feedback-->
                        <div v-if="inputState.email !== null && !inputState.email" role="alert"
                             aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                            {{ $t('sign_up.errors.email') }}
                        </div>
                    </div>

                    <!--Password-->
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-lock"></i>
                            </span>
                        </div>

                        <!--Input field-->
                        <b-input type="password" name="password" v-model="input.password" placeholder="Password"
                                 :state="inputState.password"></b-input>

                        <!--Error feedback-->
                        <div v-if="inputState.password !== null && !inputState.password" role="alert"
                             aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                            {{ $t('sign_up.errors.password') }}
                        </div>
                    </div>

                    <!--Password confirmation-->
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fas fa-lock"></i>
                            </span>
                        </div>

                        <!--Input field-->
                        <b-input type="password" name="passwordConfirm" v-model="input.passwordConfirm"
                                 :placeholder="$t('sign_up.placeholders.confirm_password')"
                                 :state="inputState.passwordsNotMatch"></b-input>

                        <!--Error feedback-->
                        <div v-if="inputState.passwordsNotMatch !== null && !inputState.passwordsNotMatch" role="alert"
                             aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                            {{ $t('sign_up.errors.confirm_password') }}
                        </div>
                    </div>

                    <b-btn type="button" variant="primary" class="margin-top-10" @click="register">
                        <i class="fas fa-user-plus"></i>
                        {{ $t('sign_up.buttons.sign_up') }}
                    </b-btn>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { EventBus } from '../utils'
import WarningMessage from '../components/WarningMessage'

export default {
  name: 'SignUp',
  components: { WarningMessage },
  data () {
    return {
      input: {
        username: '',
        email: '',
        password: '',
        passwordConfirm: ''
      },
      inputState: {
        username: null,
        email: null,
        password: null,
        passwordsNotMatch: null
      },
      errorMsg: {
        msg: ''
      }
    }
  },
  methods: {
    register () {
      // Check inputs
      const usernameZero = (this.input.username.length === 0) // If username is empty
      const emailZero = (this.input.email.length === 0) // If email is empty
      const passwordZero = (this.input.password.length === 0) // If password is empty
      const passwordsNotMatch = (this.input.password !== this.input.passwordConfirm) // If password confirmation matches
      const error = (usernameZero || emailZero || passwordZero || passwordsNotMatch) // If we should submit the form or not

      // Set text field states
      this.inputState.username = usernameZero ? false : null
      this.inputState.email = emailZero ? false : null
      this.inputState.password = passwordZero ? false : null
      this.inputState.passwordsNotMatch = passwordsNotMatch ? false : null

      if (error) {
        return
      }

      // Register
      this.$store.dispatch('register', {
        username: this.input.username,
        email: this.input.email,
        password: this.input.password
      }).then(() => {
        // Check that there is no error before going to the login screen
        if (this.errorMsg.msg.length === 0) {
          this.$router.push('/')
        }
      })
    }
  },
  mounted () {
    EventBus.$on('failed-registering', (msg) => {
      // todo: this does not get called if the store emits failed-registering event!
      this.errorMsg.msg = msg
    })
  },
  beforeDestroy () {
    EventBus.$off('failed-registering')
  }
}
</script>

<style scoped>
    .input-group-text {
        background-color: #ffffff;
    }
</style>
