<template>
    <div>
        <!--Warning message for errors-->
        <warning-message :error-msg="errorMsg"></warning-message>

        <!--Login form-->
        <b-row>
            <b-col md="6" offset="3">
                <b-card :title="$t('login.title')">
                    <b-row>
                        <b-col>
                            <!--Username-->
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-user"></i>
                                    </span>
                                </div>

                                <!--Input field-->
                                <b-input type="text" name="username" v-model="input.username"
                                         :placeholder="$t('login.placeholders.username')"
                                         :state="inputState.username"></b-input>

                                <!--Error feedback-->
                                <div v-if="inputState.username !== null && !inputState.username" role="alert"
                                     aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                                    {{ $t('login.errors.username') }}
                                </div>
                            </div>

                            <!--Password-->
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-lock"></i>
                                    </span>
                                </div>

                                <!--Input field-->
                                <b-input type="password" name="password" v-model="input.password"
                                         :placeholder="$t('login.placeholders.password')"
                                         :state="inputState.password"></b-input>

                                <!--Error feedback-->
                                <div v-if="inputState.password !== null && !inputState.password" role="alert"
                                     aria-live="assertive" aria-atomic="true" class="invalid-feedback">
                                    {{ $t('login.errors.password') }}
                                </div>
                            </div>

                            <!--Login button-->
                            <b-btn type="button" variant="primary" @click="login">
                                <i class="fas fa-sign-in-alt"></i>
                                {{ $t('login.buttons.login') }}
                            </b-btn>
                        </b-col>
                    </b-row>

                    <!--Academic login-->
                    <b-row>
                        <div class="col">
                            <hr/>
                            <b-btn variant="warning"
                                   href="https://wayf.grnet.gr/?entityID=https://inventory.dev-clarin.ilsp.gr/login&return=https://inventory.dev-clarin.ilsp.gr/saml2/login/?next=https://socialwebobservatory.iit.demokritos.gr">
                                <i class="fas fa-graduation-cap"></i>
                                {{ $t('login.buttons.academic_login') }}
                            </b-btn>
                        </div>
                    </b-row>

                    <!--Link to registration-->
                    <b-row class="mt-3">
                        <b-col>
                            <a href="#" @click="register">
                                {{ $t('login.buttons.sign_up_prompt') }}
                            </a>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { EventBus } from '../utils'
import WarningMessage from '../components/WarningMessage'

export default {
  name: 'Login',
  components: { WarningMessage },
  data () {
    return {
      input: {
        username: '',
        password: ''
      },
      inputState: {
        username: null,
        password: null
      },
      errorMsg: {
        msg: ''
      }
    }
  },
  methods: {
    login () {
      // Check inputs
      const usernameZero = (this.input.username.length === 0) // If username is empty
      const passwordZero = (this.input.password.length === 0) // If password is empty
      const error = (usernameZero || passwordZero) // If we should submit the form or not

      // Set text field states
      this.inputState.username = usernameZero ? false : null
      this.inputState.password = passwordZero ? false : null

      if (error) {
        return
      }

      // Login
      this.$store.dispatch('login', {
        username: this.input.username,
        password: this.input.password
      }).then(() => {
        // Find which page we should go to next, and go to it
        const nextPage = (this._.has(this.$route.query, 'redirect') ? this.$route.query.redirect : '/entities')
        this.$router.push(nextPage)
      })
    },
    register () {
      // todo: doesn't show /signup on URL bar on Chrome?
      this.$router.push({ name: 'signup' })
    }
  },
  mounted () {
    // Register event for failed authentication
    EventBus.$on('failed-authentication', (msg) => {
      this.errorMsg.msg = msg.toString()
    })
  },
  beforeDestroy () {
    EventBus.$off('failed-authentication')
  }
}
</script>

<style scoped>
    .input-group-text {
        background-color: #ffffff;
    }
</style>
