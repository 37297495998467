<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="title">{{ $t('settings.title') }}</h1>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h2>{{ $t('settings.sections.entities_list.title') }}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <b-form inline>
                    <label for="page-size-select">{{ $t('settings.sections.entities_list.items_per_page') }}:&nbsp;</label>
                    <b-form-select v-model="settings.pageSize" :options="pageSizes"
                                   id="page-size-select"></b-form-select>
                </b-form>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <!--Save settings button-->
                <b-button variant="success" v-on:click="saveSettings">
                    <i class="fas fa-save"></i>
                    {{ $t('settings.buttons.save_settings') }}
                </b-button>

                <!--Saving animation-->
                <span class="saving-text" v-if="saving">
                    <i class="fas fa-spinner fa-pulse"></i> {{ $t('settings.messages.saving') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Settings',
  data () {
    return {
      saving: false,
      pageSizes: [
        10,
        25,
        50,
        100
      ],
      settings: {
        pageSize: 10
      }
    }
  },
  mounted () {
    // Fetch settings from elasticsearch, if they exist for this user
    this.$store.dispatch('getSettings')
      .then(({ data }) => {
        // If settings for the user were found in Elasticsearch, use them
        if (data.found === true) {
          this.settings = data._source
        }
      })
  },
  methods: {
    saveSettings () {
      // Show saving animation
      this.saving = true

      // Get the settings
      const settings = this.settings

      this.$store.dispatch('saveSettings', settings)
        .then(() => {
          // Save the settings to vuex store
          this.$store.commit('setUserSettings', {
            userSettings: settings
          })

          // Stop the saving animation
          this.saving = false
        })
    }
  }
}
</script>

<style scoped>
    h1.title {
        text-align: center;
    }

    .container {
        text-align: left;
    }

    /* Spaces between rows */
    .row {
        margin-top: 15px;
    }

    .row:first-child {
        margin-top: 0;
    }

    .saving-text {
        margin-left: 20px;
    }
</style>
