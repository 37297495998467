<template>
    <div class="my-entities-container">
        <!--Title row-->
        <b-row>
            <!--Title-->
            <b-col class="text-left">
                <h1>{{ $t('my_entities.title') }}</h1>
            </b-col>

            <!--Public entities switch & New entity button-->
            <b-col class="text-right">
                <b-row>
                    <!--Public entities switch-->
                    <b-col cols="7">
                        <b-checkbox v-model="showPublicEntities"
                                    class="public-checkbox"
                                    @input="updateEntitiesList"
                                    :switch="true">
                            {{ $t('my_entities.buttons.show_public') }}
                        </b-checkbox>
                    </b-col>

                    <!--New entity button-->
                    <b-col cols="5">
                        <b-btn variant="success" v-on:click="$router.push('entities/new')">
                            <i class="fas fa-plus"></i>
                            {{ $t('my_entities.buttons.new_entity') }}
                        </b-btn>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!--Warning message-->
        <warning-message :error-msg="errorMsg"></warning-message>

        <!--Entities list row-->
        <b-row class="mt-2">
            <b-col>
                <b-tabs :lazy="true" v-model="tabIndex">
                    <b-tab v-for="entityType in entities" :key="entityType.type">
                        <!--Tab title-->
                        <template slot="title">
                            <!--Entity type icon-->
                            <i class="fas" :class="entityType.icon"></i>

                            <!--Entity type title-->
                            {{ entityType.type }}

                            <!--Entity count-->
                            <b-badge>{{ entityType.entitiesCount }}</b-badge>
                        </template>

                        <!--Entities list-->
                        <b-list-group class="list-group-left">
                            <b-list-group-item v-for="item in entityType.items"
                                               :key="item['_id']"
                                               class="entity-list-item"
                                               @click.self="showEntity(item)">
                                <!--Entity avatar-->
                                <b-avatar v-if="!_.isUndefined(item['_source']['profile_image']) && item['_source']['profile_image'].length > 0"
                                          :src="item['_source']['profile_image']"
                                          size="1.6rem"
                                          variant="light">
                                </b-avatar>

                                <!--Entity name-->
                                {{ item['_source'].name }}

                                <!--Public badge-->
                                <b-badge v-if="item['_source'].public"
                                         class="public-entity-badge"
                                         v-b-tooltip.hover.d500=""
                                         :title="$t('my_entities.messages.public_explanation')">
                                    <i class="far fa-eye"></i> {{ $t('my_entities.messages.public_tag') }}
                                </b-badge>

                                <!--Action buttons-->
                                <list-action-buttons class="float-right"
                                                     :dashboard-btn="true"
                                                     :can-edit="userIsOwner(item)"
                                                     @dashboard="openDashboard(item)"
                                                     @edit="editEntity(item)"
                                                     @delete="deleteEntity(item)">
                                </list-action-buttons>

                                <!--Social icons-->
                                <entity-social-buttons class="float-right social-buttons-container"
                                                       :entity-data="item['_source']">
                                </entity-social-buttons>
                            </b-list-group-item>
                        </b-list-group>

                        <!--Pagination controls-->
                        <b-row v-if="entityType.entitiesCount > pageSize" class="mt-2">
                            <b-col>
                                <!--suppress HtmlDeprecatedAttribute -->
                                <b-pagination :total-rows="entityType.entitiesCount"
                                              v-model="entityType.currentPage"
                                              :per-page="pageSize"
                                              align="center"
                                              @input="fetchEntities(entityType.currentPage, entityType.type, showPublicEntities)">
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>

        <!--Modal with entity info-->
        <entity-view-modal :modal="modal" @edit="editEntity"></entity-view-modal>
    </div>
</template>

<script>
import EntityViewModal from '../../components/EntityViewModal'
import WarningMessage from '../../components/WarningMessage'
import ListActionButtons from '../../components/ListActionButtons'
import EntitySocialButtons from '../../components/EntitySocialButtons'

export default {
  name: 'MyEntities',
  components: { EntitySocialButtons, ListActionButtons, EntityViewModal, WarningMessage },
  data () {
    return {
      pageSize: this.$store.getters.userSettings.pageSize,
      tabIndex: parseInt(localStorage.entityTypeTab) || 0,
      showPublicEntities: this.$store.getters.getPublicEntitiesValue,
      entities: this.$store.getters.entityTypes,
      errorMsg: {
        msg: ''
      },
      modal: {
        show: false,
        entity: null
      }
    }
  },
  mounted () {
    // Get entities when the view loads
    this.updateEntitiesList()
  },
  methods: {
    userIsOwner (entity) {
      // Get owners from the entity data
      const owners = entity._source.owners

      // Check if the username saved in localStorage (which always happens when logging in) exists in the list
      return (owners.indexOf(localStorage.username) !== -1 || localStorage.username === 'superuser')
    },
    openDashboard ({ _id }) {
      // Go to the dashboard for this entity
      this.$router.push(`dashboard/${_id}`)
    },
    showEntity (item) {
      this.modal.entity = item._source
      this.modal.entityId = item._id
      this.modal.show = true
    },
    editEntity (entity) {
      this.$router.push({
        name: 'editentity',
        params: {
          id: entity._id
        }
      })
    },
    deleteEntity (item) {
      // Show alert to confirm deletion
      if (!confirm(this.$t('my_entities.messages.delete_confirmation') + ' "' + item._source.name + '"?')) {
        return
      }

      // Delete the item and then call updateEntitiesList to show the change in the list
      this.$store.dispatch('deleteEntity', item._id)
        .then(this.updateEntitiesList)
    },
    fetchEntities (newPage, category, publicEntities) {
      // Get the entity category's data
      const entityType = this._.find(this.entities, { type: category })

      // Get the number of entities the page should start from
      const pageNumber = newPage || entityType.currentPage
      let start = Math.max(pageNumber - 1, 0) * this.pageSize

      // If the current page has no more items (e.g. when deleting entities or changing page size) go to the first page
      if (start >= entityType.entitiesCount) {
        start = 0
        entityType.currentPage = 1
      }

      // Get entities for the selected page and show them in the page
      this.$store.dispatch('fetchEntities', {
        start: start,
        rows: this.pageSize,
        category: category,
        public: publicEntities
      }).then(({ data }) => {
        // noinspection JSUnresolvedVariable
        entityType.items = data.hits.hits
      }).catch((err) => {
        this.errorMsg.msg = err.toString()
      })
    },
    updateEntitiesList () {
      // See whether we should show the public entities or not (and copy its value so it doesn't change while requests
      // are being done)
      const publicEntities = this.showPublicEntities

      // Get the number of documents
      this.$store.dispatch('getEntitiesNumber', {
        public: publicEntities
      }).then(({ data }) => {
        // Get the entity counts per type from the response
        // noinspection JSUnresolvedVariable
        const entitiesPerType = data.counts

        // Add the counts to the entity type tabs
        this._.each(this.entities, entityType => {
          // Add the entity count to this entity type
          entityType.entitiesCount = entitiesPerType[entityType.type]

          // Fetch entities for this entity type
          this.fetchEntities(entityType.currentPage, entityType.type, publicEntities)
        })
      })
    }
  },
  watch: {
    tabIndex: function (newIndex) {
      localStorage.entityTypeTab = newIndex
    },
    showPublicEntities: function (selection) {
      localStorage.publicEntities = selection
    }
  }
}
</script>

<style scoped>
    .entity-list-item {
        line-height: 31px;
    }

    .list-group-left {
        text-align: left;
    }

    /*noinspection CssUnusedSymbol*/
    .list-group-item:first-child {
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .social-buttons-container {
        margin-right: 15px;
    }

    .public-entity-badge {
        background-color: #62bbff;
    }

    .public-checkbox {
        height: 100%;
        /*Make the checkbox appear centered...*/
        padding-top: 8px;
    }

    .my-entities-container {
        padding-bottom: 20px;
    }
</style>
