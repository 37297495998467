<template>
    <b-modal v-model="modal.show" size="lg">
        <!--Modal header-->
        <div slot="modal-header"
             v-if="modal.entity !== null"
             class="modal-header-container">
            <h4 class="modal-header-title">
                <!--Category badge-->
                <b-badge variant="info">{{ modal.entity.category }}</b-badge>

                <!--Entity name-->
                {{ modal.entity.name }}

                <!--Entity avatar-->
                <b-avatar
                        v-if="!_.isUndefined(modal.entity['profile_image']) && modal.entity['profile_image'].length > 0"
                        :src="modal.entity['profile_image']"
                        class="entity-avatar"
                        size="2rem"
                        variant="light">
                </b-avatar>
            </h4>
        </div>

        <!--suppress HtmlUnknownBooleanAttribute -->
        <b-container v-if="modal.entity !== null" class="text-left" fluid>
            <!--Default properties-->
            <entity-field v-for="field in fields"
                          :key="field.key"
                          :entity="modal.entity"
                          :field="field"></entity-field>

            <!--Extra properties-->
            <entity-field v-for="field in extraProperties"
                          :key="field.key"
                          :entity="modal.entity"
                          :field="field"></entity-field>
        </b-container>

        <!--Modal footer-->
        <div slot="modal-footer">
            <b-btn v-if="showEditBtn" variant="primary" class="edit-button"
                   @click.stop="$emit('edit', {'_id': modal.entityId})">
                <i class="fas fa-pencil-alt"></i>
                {{ $t('my_entities.buttons.edit') }}
            </b-btn>

            <b-btn variant="outline-secondary" @click="modal.show = false">
                <i class="fas fa-times"></i>
                {{ $t('my_entities.buttons.close') }}
            </b-btn>
        </div>
    </b-modal>
</template>

<script>
import EntityField from './EntityField'

export default {
  name: 'EntityViewModal',
  components: { EntityField },
  props: {
    modal: Object
  },
  data () {
    return {
      fields: this.$store.getters.entityFieldsViewer,
      extraProperties: [],
      showEditBtn: false
    }
  },
  mounted () {
    // Watch the entity variable for changes
    this.$watch(
      () => {
        return this.modal.entity
      },
      (newVal, oldVal) => {
        // The category was updated, fetch properties for it from API
        this.$store.dispatch('getCategoryFields', newVal.category)
          .then(({ data }) => {
            // Add new fields to the form as extra properties
            this.extraProperties = data.fields
          }, (error) => {
            console.trace(error.message)
          })

        // Check if the logged in user is an owner of the entity, and hide/show the edit button accordingly
        this.showEditBtn = (this.modal.entity.owners.indexOf(localStorage.username) !== -1 ||
            localStorage.username === 'superuser')
      }
    )
  }
}
</script>

<style scoped>
    .edit-button {
        margin-right: 10px;
    }

    /*Remove bottom margin from modal title*/
    h4.modal-header-title {
        margin-bottom: 0;
    }

    /*Make modal header take up entire width (to position avatar on the right)*/
    .modal-header-container {
        width: 100%;
    }

    /*Make entity avatar float to the right*/
    .entity-avatar {
        float: right;
    }
</style>
