import VueI18n from 'vue-i18n'
import Vue from 'vue'
import en from '../lang/en.json'
import el from '../lang/el.json'

Vue.use(VueI18n)

let defaultLocale = 'en'

if ('lang' in localStorage) {
  // Read user's previously selected language from localStorage
  defaultLocale = localStorage.lang
}

export const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: 'en', // fallback locale
  messages: { en, el } // set locale messages
})
