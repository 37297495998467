<template>
    <b-card class="item-card" :style="{ minHeight: minHeight }">
        <!--Card icon-->
        <div class="row card-icon">
            <div class="col">
                <i :style="{ 'color': color }" :class="iconClass"></i>
            </div>
        </div>

        <!--Card title-->
        <div class="row card-title">
            <div class="col">
                {{ title }}
            </div>
        </div>

        <!--Card text (HTML)-->
        <div class="row card-text">
            <div class="col">
                <p class="card-text" v-html="text"></p>
            </div>
        </div>

        <!--Buttons-->
        <div class="row" v-for="button in buttons" :key="button.to">
            <div class="col">
                <router-link :to="button.to">
                    <b-btn :variant="button.variant" class="foo bar">{{ button.label }}</b-btn>
                </router-link>
            </div>
        </div>
    </b-card>
</template>

<script>
export default {
  name: 'IconCard',
  props: {
    iconClass: String,
    title: String,
    color: String,
    text: String,
    minHeight: String,
    buttons: Array
  }
}
</script>

<style scoped>
    .item-card {
        text-align: center;
    }

    .row.card-icon {
        font-size: 75px;
        margin-bottom: 0;
    }

    .row.card-title {
        font-size: 26px;
    }

    .row.card-text {
        text-align: justify;
        margin-bottom: 0;
    }

    p.card-text {
        margin-bottom: 0;
    }
</style>
