import Vue from 'vue'
import Router from 'vue-router'
import LoginComponent from './views/Login.vue'
import LogoutComponent from './views/Logout.vue'
import SignUpComponent from './views/SignUp.vue'
import MyEntitiesComponent from './views/entities/MyEntities.vue'
import store from '@/store'
import ToolsComponent from './views/Tools.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: {
        name: 'login'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginComponent
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutComponent
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUpComponent
    },
    {
      path: '/tools',
      name: 'Tools',
      component: ToolsComponent
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/sources',
      name: 'sources',
      component: () => import('./views/SourcesOverview.vue')
    },
    {
      path: '/organize-sources',
      name: 'organize-sources',
      component: () => import('./views/source_groups/OrganizeSources.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/organize-sources/new',
      name: 'new-source-group',
      component: () => import('./views/source_groups/NewSourceGroup.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/organize-sources/edit/:id',
      name: 'edit-source-group',
      component: () => import('./views/source_groups/EditSourceGroup.vue'),
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/entities',
      name: 'entities',
      component: MyEntitiesComponent,
      meta: { requiresAuth: true }
    },
    {
      path: '/entities/new',
      name: 'newentity',
      component: () => import('./views/entities/NewEntity.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/entities/edit/:id',
      name: 'editentity',
      component: () => import('./views/entities/EditEntity.vue'),
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/dashboard',
      name: 'select-entity',
      component: () => import('./views/dashboard/SelectEntity.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:id',
      name: 'dashboard',
      props: true,
      component: () => import('./views/dashboard/Dashboard.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ahedd/analytics',
      name: 'ahedd-analytics',
      component: () => import('./views/dashboard/Dashboard.vue'),
      props: {
        id: 'Q9MLkmoBVqTKr-huWzwl'
      }
    },
    {
      path: '/ahedd/technology',
      name: 'ahedd-technology',
      component: () => import('./views/dashboard/Dashboard.vue'),
      props: {
        id: 'RdMgk2oBVqTKr-hu7DzZ'
      }
    },
    {
      path: '/ahedd/innovation-business',
      name: 'ahedd-innovation-business',
      component: () => import('./views/dashboard/Dashboard.vue'),
      props: {
        id: 'RtMjk2oBVqTKr-hu8Dxw'
      }
    },
    {
      path: '/ahedd/social-legal-data',
      name: 'ahedd-social-legal-data',
      component: () => import('./views/dashboard/Dashboard.vue'),
      props: {
        id: 'R9Mlk2oBVqTKr-huMjxs'
      }
    },
    {
      path: '/ahedd/covid',
      name: 'ahedd-covid',
      component: () => import('./views/dashboard/Dashboard.vue'),
      props: {
        id: 'vQmphnABeJ3Ykde1TYzI'
      }
    },
    {
      path: '/covid19',
      name: 'covid19',
      component: () => import('./views/dashboard/Dashboard.vue'),
      props: {
        id: 'vQmphnABeJ3Ykde1TYzI'
      }
    }
  ]
})

// Check if login is required before each route
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
    // Redirect to login
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    // Allow to continue
    next()
  }
})

export default router
