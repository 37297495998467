<template>
    <highcharts :options="chartOptions"></highcharts>
</template>

<script>
export default {
  name: 'TopTermsBar',
  props: {
    entityId: String,
    dateRange: Object,
    termsType: String,
    color: String,
    index: String,
    field: String,
    title: String,
    size: Number,
    filters: Object
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'bar'
        },
        title: {
          text: this.title
        },
        series: [],
        xAxis: {
          categories: [],
          max: 15,
          scrollbar: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          enabled: false
        }
      }
    }
  },
  methods: {
    updateChart: function () {
      // Create request parameters
      const requestData = {
        // entity_id: this.entityId,
        date_start: this.dateRange.start,
        date_end: this.dateRange.end,
        index: this.index,
        field: this.field,
        size: this.size
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestData, this.filters)
      }

      this.$store.dispatch('chartTermsAggregation', requestData)
        .then(({ data }) => {
          const buckets = data.terms

          this.chartOptions.xAxis.scrollbar.enabled = (buckets.length > 15)
          this.chartOptions.xAxis.max = (buckets.length > 15) ? 15 : null

          // Update chart categories
          this.chartOptions.xAxis.categories = this._.map(buckets, 0)

          // Add new array of series with a new series
          this.chartOptions.series = [{
            name: this.termsType,
            data: this._.map(buckets, 1),
            color: this.color
          }]
        })
    },
    setTexts: function () {
      // Title will have changed, so update it in the chart
      this.chartOptions.title.text = this.title

      // Update the axis
      this.chartOptions.yAxis.title.text = this.$t('components.top_terms_bar.number_of') + ' ' + this.termsType
    }
  },
  watch: {
    dateRange: function () {
      // Date range changed, update the chart
      this.updateChart()
    },
    filters: function () {
      // Update the chart if the filters change
      this.updateChart()
    },
    '$i18n.locale': function () {
      // Update texts of the chart
      this.setTexts()
    }
  },
  mounted () {
    this.updateChart()
    this.setTexts()
  }
}
</script>

<style scoped>

</style>
