<template>
    <b-card bg-variant="light">
        <!--Header-->
        <b-row slot="header" class="mb-0">
            <!--Title-->
            <b-col class="sentiment-items-title">
                {{ $t('components.sentiment_over_time.messages.items_between') + ' ' + itemsDateRangeStart + ' ' + $t('components.sentiment_over_time.messages.and') + ' ' + itemsDateRangeEnd}}
            </b-col>

            <!--Close button-->
            <b-col cols="2" class="close-sentiment-items-col">
                <b-btn variant="danger"
                       size="sm"
                       @click="$emit('close')">
                    <i class="fas fa-times"></i> {{ $t('components.sentiment_over_time.buttons.close') }}
                </b-btn>
            </b-col>
        </b-row>

        <!--Items-->
        <b-tabs :lazy="true"
                v-model="currentTab"
                @input="pagination.current = 1">
            <b-tab v-for="polarity in sortedTabs"
                   :key="polarity">
                <!--Tab title-->
                <template slot="title">
                                <span :style="tabs[polarity].labelStyle">
                                    <!--Icon-->
                                    <i class="far" :class="tabs[polarity].icon"></i>

                                    <!--Title-->
                                    {{ tabs[polarity].label }}
                                </span>

                    <!--Entity count-->
                    <b-badge class="item-count-badge">
                        {{ items[polarity].length }}
                    </b-badge>
                </template>

                <!--No items message-->
                <b-row v-if="items[polarity].length === 0" class="no-items-msg-row">
                    <b-col offset="3" cols="6">
                        <!--suppress HtmlUnknownBooleanAttribute -->
                        <b-alert show>
                            {{ $t('components.sentiment_over_time.messages.no_items_start') + ' ' + tabs[polarity].label.toLowerCase() + ' ' + $t('components.sentiment_over_time.messages.no_items_end') + '!' }}
                        </b-alert>
                    </b-col>
                </b-row>

                <!--Pagination-->
                <b-row v-show="items[polarity].length > pagination.pageSize">
                    <b-col>
                        <!--suppress HtmlDeprecatedAttribute -->
                        <b-pagination
                                v-model="pagination.current"
                                :total-rows="items[polarity].length"
                                :per-page="pagination.pageSize"
                                class="pagination-controls"
                                limit="3"
                                align="center">
                        </b-pagination>
                    </b-col>
                </b-row>

                <!--Items list-->
                <b-row class="items-list-row">
                    <b-col>
                        <documents-list :items="getItemsOfCurrentPage(items[polarity])"></documents-list>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import DocumentsList from '../../DocumentsList'

export default {
  name: 'SentimentItemsList',
  components: { DocumentsList },
  props: {
    dateRange: Object,
    items: Object,
    showTab: String
  },
  data () {
    return {
      pagination: {
        current: 1,
        pageSize: 15
      },
      currentTab: 0,
      tabs: {
        positive: {
          icon: 'fa-smile',
          label: this.$t('components.sentiment_over_time.messages.positive'),
          labelStyle: {
            color: '#009100'
          }
        },
        neutral: {
          icon: 'fa-meh-blank',
          label: this.$t('components.sentiment_over_time.messages.neutral'),
          labelStyle: {
            color: '#5b5b5b'
          }
        },
        negative: {
          icon: 'fa-frown',
          label: this.$t('components.sentiment_over_time.messages.negative'),
          labelStyle: {
            color: '#b7000c'
          }
        }
      }
    }
  },
  methods: {
    getItemsOfCurrentPage: function (allItems) {
      // Return the sentiment items for the currently selected page
      return allItems.slice((this.pagination.current - 1) * this.pagination.pageSize,
        this.pagination.current * this.pagination.pageSize)
    },
    selectTab: function (tabToShow) {
      if (!this._.isNull(tabToShow)) {
        // Find the index of the tab to show and set it as current
        this.currentTab = this.sortedTabs.indexOf(tabToShow.toLowerCase())
      }
    }
  },
  mounted () {
    // Show the set tab
    this.selectTab(this.showTab)
  },
  computed: {
    itemsDateRangeStart: function () {
      return this.dateRange.start.toLocaleString()
    },
    itemsDateRangeEnd: function () {
      return this.dateRange.end.toLocaleString()
    },
    sortedTabs: function () {
      return Object.keys(this.tabs).sort().reverse()
    }
  },
  watch: {
    showTab: function (tabToShow) {
      // Show the new tab that we should show
      this.selectTab(tabToShow)
    }
  }
}
</script>

<style scoped>
    .close-sentiment-items-col {
        text-align: right;
    }

    .sentiment-items-title {
        line-height: 31px;
        font-size: 16px;
        font-weight: bold;
    }

    .pagination-controls, .items-list-row, .no-items-msg-row {
        margin-top: 10px;
    }

    .pagination-controls {
        margin-bottom: 0;
    }

    .no-items-msg-row {
        text-align: center;
    }

    .item-count-badge {
        border: 1px solid #787878;
        background-color: #9c9c9c;
    }
</style>
