<template>
    <div>
        <!--Title-->
        <b-row>
            <b-col>
                <h2>{{ $t('components.entity_editor.edit_entity_title') }}</h2>
            </b-col>
        </b-row>

        <!--Entity Editor (for new entity creation)-->
        <b-row>
            <b-col>
                <EntityEditor :entityId="id"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import EntityEditor from '../../components/EntityEditor'

export default {
  name: 'EditEntity',
  components: { EntityEditor },
  props: ['id']
}
</script>
