<template>
    <span>Logging out...</span>
</template>

<script>
export default {
  name: 'Logout',
  mounted () {
    // Logout the user and go to home page
    this.$store.dispatch('logout')
      .then(() => this.$router.push('/'))
  }
}
</script>
