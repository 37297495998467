<template>
    <b-card :title="title"
            bg-variant="light"
            class="single-value-container">
        <h1>{{ value }}</h1>
    </b-card>
</template>

<script>
export default {
  name: 'BaseSingleValueDisplay',
  props: {
    entityId: String,
    dateRange: Object,
    type: String,
    title: String,
    filters: Object
  },
  data () {
    return {
      value: 'Loading...'
    }
  },
  methods: {
    updateValue: function () {
      this.value = -1
    }
  },
  watch: {
    dateRange: function () {
      // Date range changed, update the chart
      this.updateValue()
    },
    entityId: function () {
      // Update the chart if the entity ID changes
      this.updateValue()
    },
    filters: function () {
      // Update the chart if the filters change
      this.updateValue()
    }
  },
  mounted () {
    this.updateValue()
  }
}
</script>

<style scoped>
    .single-value-container {
        display: inline-block;
    }
</style>
