<template>
    <div>
        <!--Error message-->
        <warning-message :error-msg="errorMsg"></warning-message>

        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-body>
                        <!--Group name input-->
                        <b-row class="mb-2">
                            <b-col cols="2">
                                <label class="float-left">{{ $t('components.group_editor.group_name') }}:</label>
                            </b-col>
                            <b-col>
                                <b-input type="text"
                                         v-model.trim="groupName">
                                </b-input>
                            </b-col>
                        </b-row>

                        <!--Data source selection-->
                        <b-row>
                            <b-col cols="2">
                                <label class="float-left">{{ $t('components.group_editor.data_sources') }}:</label>
                            </b-col>
                            <b-col>
                                <data-source-selector :selected-items="selectedItems"/>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!--Save/cancel footer buttons-->
                    <b-card-footer>
                        <b-row>
                            <b-col md="3" offset="3">
                                <b-btn variant="success" @click="saveGroup">
                                    <i class="fas fa-save"></i>
                                    {{ $t('components.group_editor.buttons.save') }}
                                </b-btn>
                            </b-col>
                            <b-col md="3">
                                <b-btn variant="outline-secondary" @click="cancelGroup">
                                    <i class="fas fa-times"></i>
                                    {{ $t('components.group_editor.buttons.cancel') }}
                                </b-btn>
                            </b-col>
                        </b-row>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import DataSourceSelector from './input/DataSourceSelector'
import WarningMessage from './WarningMessage'

export default {
  name: 'SourceGroupEditor',
  components: { DataSourceSelector, WarningMessage },
  props: {
    groupId: String
  },
  data () {
    return {
      editing: false,
      groupName: '',
      selectedItems: {
        items: []
      },
      errorMsg: {
        msg: ''
      }
    }
  },
  methods: {
    showErrorMsg (msg) {
      this.errorMsg.msg = msg
      window.scrollTo(0, 0)
    },
    saveGroup: function () {
      // Gather the required data
      const groupData = {
        name: this.groupName,
        sources: this.selectedItems.items
      }

      // Check if the data is valid
      if (groupData.name == null || groupData.name.length === 0 ||
          groupData.sources.length === 0) {
        this.showErrorMsg('Please fill the group name field and select at least one source!')
        return
      }

      // Get appropriate promise (for new group or updating existing one)
      let promise
      if (!this.editing) {
        // Save new group
        promise = this.$store.dispatch('addSourceGroup', groupData)
      } else {
        // Update the group
        promise = this.$store.dispatch('updateSourceGroup', {
          id: this.groupId,
          data: groupData
        })
      }

      // Wait for promise and redirect to source groups
      promise.then(() => {
        this.$router.push({ name: 'organize-sources' })
      }).catch(err => {
        // Show error message on page and scroll to top,
        // to make it visible to the user
        this.showErrorMsg(err.response.data.message)

        // Trace the error in the console
        console.trace(err.message)
      })
    },
    cancelGroup: function () {
      this.$router.push('/organize-sources')
    }
  },
  mounted () {
    if (!this._.isUndefined(this.groupId) && !this._.isNull(this.groupId)) {
      // We are editing a group
      this.editing = true

      // Get the data for the group to add to the form
      this.$store.dispatch('fetchSourceGroup', this.groupId)
        .then(({ data }) => {
          // Set the properties from the received data
          this.groupName = data.name
          this.selectedItems.items = data.sources
        })
    }
  }
}
</script>

<style scoped>
    label {
        font-weight: bold;
    }
</style>
