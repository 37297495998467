<template>
    <b-row>
        <b-col md="3">
            <!--Label with key of property-->
            <label class="float-left">{{ property.label }}:</label>
        </b-col>
        <b-col>
            <!--Simple text input-->
            <b-input type="text"
                     :id="inputId"
                     v-if="inputCategory === 'text'"
                     v-model.trim="property.value">
            </b-input>

            <!--Text input hint-->
            <b-alert v-if="showHint" class="input-field-hint" variant="primary" show>
                {{ property.hint }}
            </b-alert>

            <!--Boolean (checkbox) input-->
            <b-checkbox v-if="inputCategory === 'boolean'"
                        v-model="property.value"
                        class="boolean-input"
                        :switch="true">
            </b-checkbox>

            <!--Keyword input-->
            <keyword-input v-if="inputCategory === 'list'"
                           :suggestions-field="property.suggestionsField"
                           :suggestions-index="property.suggestionsIndex"
                           v-model="property.value">
            </keyword-input>

            <!--Category input (predefined categories)-->
            <multiselect v-if="inputCategory === 'category'"
                         v-model="property.value"
                         :options="categories"
                         @select="property.callback"
                         placeholder="Select category">
            </multiselect>

            <!--Name input with suggestions from API-->
            <name-input v-if="inputCategory === 'name'"
                        v-model="property.value">
            </name-input>
        </b-col>
    </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'
import NameInput from './NameInput'
import KeywordInput from './KeywordInput'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'EntityProperty',
  components: { KeywordInput, NameInput, Multiselect },
  props: {
    property: Object,
    currCategory: String
  },
  data () {
    return {
      options: [], // Options for the list
      showHint: false,
      categories: this.$store.getters.entityTypeNames,
      inputCategory: '',
      inputId: 'entity_property_' + this.property.key
    }
  },
  mounted () {
    // Check what kind of property this is to display the appropriate widget
    if (this.property.key === 'category') {
      // Entity category selection
      this.inputCategory = 'category'
    } else if (this.property.key === 'first_name' || this.property.key === 'fathers_name') {
      // Name field with options from server
      this.inputCategory = 'name'
    } else if (this.property.type === 'boolean') {
      // Boolean input field
      this.inputCategory = 'boolean'
    } else if (this.property.value.constructor === Array) {
      // The value is an array, so display the appropriate widget for editing it
      // Source: https://stackoverflow.com/a/26633883
      this.inputCategory = 'list'
    } else {
      // Default input category is "text"
      this.inputCategory = 'text'
    }

    // Check if we should show the hint
    if (this._.has(this.property, 'hint') && this._.isString(this.property.hint) && this.property.hint.length > 0) {
      // We have a hint, check if there is a filter
      if (this._.has(this.property, 'hintFilter') && !this._.isUndefined(this.property.hintFilter)) {
        // Set the initial value of showHint based on the filter
        this.filterHintByCategory(this.currCategory)

        // Watch for changes in the category
        this.$watch('currCategory', this.filterHintByCategory)
      } else {
        // Show the hint since there's no filter
        this.showHint = true
      }
    }
  },
  methods: {
    filterHintByCategory (category) {
      if (!this._.isNull(category)) {
        // Show the hint if the given category is included in the filter
        this.showHint = this._.includes(this.property.hintFilter, category)
      }
    }
  }
}
</script>

<style scoped>
    label {
        font-weight: bold;
    }

    .input-field-hint {
        margin-top: 10px;
        text-align: left;
    }

    .boolean-input {
        text-align: left;
    }
</style>
