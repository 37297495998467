<template>
    <div>
        <!--Error message-->
        <warning-message :error-msg="errorMsg"></warning-message>

        <!--Entity editor-->
        <b-row>
            <b-col>
                <b-card no-body>
                    <!--Card body-->
                    <b-card-body>
                        <!--Key-value inputs-->
                        <entity-property v-for="prop in editableEntityProperties"
                                         :key="prop.key"
                                         :property="prop"
                                         :curr-category="prevCategory"
                                         class="mb-2">
                        </entity-property>

                        <!--Horizontal separator for extra fields-->
                        <hr v-if="extraProperties.length > 0"/>

                        <!--Inputs for the extra properties (fetched from API)-->
                        <entity-property v-for="prop in extraProperties"
                                         :key="prop.key"
                                         :property="prop"
                                         class="mb-2">
                        </entity-property>
                    </b-card-body>

                    <!--Card footer (with save button)-->
                    <b-card-footer>
                        <b-row>
                            <b-col md="3" offset="3">
                                <b-btn variant="success" @click="saveEntity">
                                    <i class="fas fa-save"></i>
                                    {{ $t('components.entity_editor.buttons.save') }}
                                </b-btn>
                            </b-col>
                            <b-col md="3">
                                <b-btn variant="outline-secondary" @click="cancelEdit">
                                    <i class="fas fa-times"></i>
                                    {{ $t('components.entity_editor.buttons.cancel') }}
                                </b-btn>
                            </b-col>
                        </b-row>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import EntityProperty from './input/EntityProperty'
import WarningMessage from './WarningMessage'

export default {
  name: 'EntityEditor',
  components: { WarningMessage, EntityProperty },
  props: {
    entityId: String
  },
  data () {
    return {
      editing: false,
      errorMsg: {
        msg: ''
      },
      prevCategory: null,
      hiddenProperties: this.$store.getters.hiddenEntityFieldsEditor,
      entityProperties: this.initializeProperties(),
      extraProperties: []
    }
  },
  computed: {
    editableEntityProperties: function () {
      return this.entityProperties.filter(entity => {
        return !this.hiddenProperties.includes(entity.key)
      })
    }
  },
  mounted () {
    // Add the change callback to the entity category field
    const categoryField = this._.find(this.entityProperties, {
      key: 'category'
    })

    if (!this._.isUndefined(categoryField)) {
      // Add the callback to the field
      categoryField.callback = this.getFieldsForCategory
    } else {
      // This shouldn't happen...
      console.error('Error binding category field to callback!')
    }

    // Check if we are editing, to fetch the entity's information
    if (this.entityId != null) {
      this.editing = true

      // Get the data for this entity to add it to the form
      this.$store.dispatch('fetchEntity', this.entityId)
        .then(({ data }) => {
          // Get value for each of the fields from the fetched entity
          this._.each(this.entityProperties, property => {
            // Check that a value exists to prevent possible overwrite of default value with undefined for new fields
            if (this._.has(data, property.key)) {
              property.value = data[property.key]
            }
          })

          // Get any extra fields for this entity's category and display them
          this.getFieldsForCategory(data.category, data)
        }, error => {
          console.trace(error.message)
        })
    }
  },
  methods: {
    showErrorMsg (msg) {
      this.errorMsg.msg = msg
      window.scrollTo(0, 0)
    },
    initializeProperties () {
      // Create a list of properties with initial (empty) values
      const self = this

      // Get the properties from the store
      const initProps = self.$store.getters.entityFieldsEditor

      // Create copy of properties with initialized values
      return self._.map(initProps, function (prop) {
        return self._.pickBy({
          value: (prop.type === 'list') ? [] : '',
          type: prop.type,
          key: prop.key,
          label: prop.label,
          suggestionsIndex: prop.suggestionsIndex,
          suggestionsField: prop.suggestionsField,
          hint: prop.hint,
          hintFilter: prop.hintFilter
        }, value => {
          // Keep only fields that are not undefined
          return !self._.isUndefined(value)
        })
      })
    },
    getObjectFromForm () {
      const self = this
      const item = {}

      // Create function that will be used to add each property to the item object
      const itemModifier = function (o) {
        // Check if we are creating an entity and this is the created_at timestamp, or if this is the last update
        // timestamp, which should be updated in both cases (entity creation & edit)
        if ((!self.editing && o.key === 'created_at') || o.key === 'updated_at') {
          // Add current timestamp as created_at date or updated timestamp for last edit
          item[o.key] = new Date().valueOf()
        } else {
          // Add previous value
          item[o.key] = o.value
        }
      }

      // Add default & extra properties to the item object using the function
      this.entityProperties.forEach(itemModifier)
      this.extraProperties.forEach(itemModifier)

      return item
    },
    saveEntity () {
      let promise
      const entityData = this.getObjectFromForm()

      // Manually set the current user as the owner of the entity (always), until sharing with other users etc.
      // is implemented...
      if (!this._.has(entityData, 'owners') || entityData.owners.length === 0) {
        // Add the current user as the entity owner
        entityData.owners = [
          localStorage.username
        ]
      }

      // Check that the entity data is not empty
      if (entityData.name == null || entityData.name.length === 0 ||
          entityData.category == null || entityData.category.length === 0) {
        this.showErrorMsg(this.$t('components.entity_editor.errors.required_fields'))
        return
      }

      // Get appropriate promise (for new entity or updating existing one)
      if (!this.editing) {
        // Save new entity
        promise = this.$store.dispatch('addEntity', entityData)
      } else {
        // Update the entity
        promise = this.$store.dispatch('updateEntity', {
          id: this.entityId,
          data: entityData
        })
      }

      // Wait for promise and redirect to entities list
      promise.then(() => {
        this.$router.push({ name: 'entities' })
      }).catch(err => {
        // Show error message on page and scroll to top, to make it visible to the user
        this.showErrorMsg(err.response.data.message)

        // Trace the error in the console
        console.trace(err.message)
      })
    },
    cancelEdit () {
      this.$router.push('/entities')
    },
    getFieldsForCategory (category, fetchedEntity) {
      // Check if category actually changed before doing anything
      if (category !== this.prevCategory) {
        // Save new category as previous category in case it changes again
        this.prevCategory = category

        // Empty current extra properties since we are getting new ones
        // this.extraProperties.length = 0
        this.extraProperties = []

        // Get new fields
        this.$store.dispatch('getCategoryFields', category)
          .then(({ data }) => {
            // Add new fields to the form as extra properties
            this.extraProperties = data.fields.map(item => {
              // Add empty value field
              item.value = ''
              return item
            })

            // If fetchedEntity is not null, add extra property values from the object to the form
            if (fetchedEntity !== null && this.extraProperties.length > 0) {
              this.extraProperties.forEach(property => {
                // Get property value
                const propertyValue = fetchedEntity[property.key]

                // If it has a value, add it to the form
                if (propertyValue !== undefined) {
                  property.value = fetchedEntity[property.key]
                }
              })
            }
          }, error => {
            console.trace(error.message)
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
