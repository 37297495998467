<template>
    <div class="row header-container">
        <div class="col logo-col">
            <img class="swo-logo-img" src="../assets/SWO_logo.png" alt="Social Web Observatory logo">
        </div>
        <div class="col text-col">
            <span class="swo-title-text">{{ $t('sidebar.app_title') }}</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SidebarTitle'
}
</script>

<style scoped>
    .header-container {
        height: 80px;
    }

    .logo-col {
        margin-top: 10px;
        margin-left: 10px;
        padding-right: 0;
        max-width: 70px;
    }

    .text-col {
        margin-top: 5px;
        padding-left: 0;
        text-align: center;
    }

    .swo-title-text {
        color: white;
        font-size: 22px;
    }

    .swo-logo-img {
        width: 55px;
        border-radius: 7px;
    }
</style>
