<template>
    <div>
        <!--Title-->
        <b-row>
            <b-col>
                <h2>{{ $t('components.entity_editor.new_entity_title') }}</h2>
            </b-col>
        </b-row>

        <!--Entity Editor (for new entity creation)-->
        <b-row>
            <b-col>
                <EntityEditor/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// @ is an alias to /src
// noinspection NpmUsedModulesInstalled
import EntityEditor from '@/components/EntityEditor.vue'

export default {
  name: 'NewEntity',
  components: {
    EntityEditor
  }
}
</script>
