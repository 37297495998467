<template>
    <div class="container">
        <b-row>
            <b-col>
                <h1>{{ $t('tools.title') }}</h1>
            </b-col>
        </b-row>

        <!--Marketplace items-->
        <div class="row items-container">
            <div v-for="item in items" :key="item.id" class="marketplace-item">
                <marketplace-item :data="item"></marketplace-item>
            </div>
        </div>

        <b-row>
            <b-col class="attributions">
                <b-card bg-variant="light">
                    <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a>
                        from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                    <div>Icons made by <a href="https://www.flaticon.com/authors/catkuro" title="catkuro">catkuro</a>
                        from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                    <div>Icons made by <a href="https://www.flaticon.com/authors/good-ware" title="Good Ware">Good
                        Ware</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import MarketplaceItem from '../components/MarketplaceItem'

export default {
  name: 'ToolsComponent',
  components: { MarketplaceItem },
  data () {
    return {
      items: [],
      popoverShow: false
    }
  },
  mounted () {
    this.$store.dispatch('getMarketplaceItems')
      .then(({ data }) => {
        this.items = data.items
      })
  }
}
</script>

<style scoped>
    .items-container {
        display: flex;
        flex-wrap: wrap;
    }

    .marketplace-item {
        display: flex;
        padding: 0.5em;
        width: 100%;
    }

    @media all and (min-width: 40em) {
        .marketplace-item {
            width: 50%;
        }
    }

    @media all and (min-width: 60em) {
        .marketplace-item {
            width: 33.33%;
        }
    }

    .attributions {
        margin-top: 60px;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 20px;
    }
</style>
