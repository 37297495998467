<!--List of article/tweet/comments with links to open each item in a new tab and shows its domain-->
<template>
    <b-list-group>
        <!--List item-->
        <b-list-group-item
                v-for="item in items"
                :key="item.id"
                target="_blank"
                :href="item.link">
            <b-row>
                <!--Item title-->
                <b-col class="title-col" v-html="item.title">
                </b-col>

                <!--Domain-->
                <b-col :cols="domainCols" v-if="item.domain.length > 0" class="domain-col">
                    <b-badge variant="info">
                        {{ item.domain }}
                    </b-badge>
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</template>

<script>
export default {
  name: 'DocumentsList',
  props: {
    items: Array,
    domainCols: {
      type: Number,
      default: 2
    }
  }
}
</script>

<style scoped>
    .title-col {
        text-align:left;
    }
    .domain-col {
        text-align: right;
    }
</style>
