<template>
    <b-card :title="title"
            bg-variant="light"
            class="single-value-container">
        <h1>{{ value }}</h1>
    </b-card>
</template>

<script>
import BaseSingleValueDisplay from './BaseSingleValueDisplay.vue'

export default {
  name: 'UniqueCount',
  extends: BaseSingleValueDisplay,
  props: {
    field: String
  },
  methods: {
    updateValue: function () {
      // Gather request parameters
      const requestParams = {
        entity_id: this.entityId,
        type: this.type,
        date_start: this.dateRange.start,
        date_end: this.dateRange.end,
        field: this.field,
        aggregation_type: 'cardinality'
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestParams, this.filters)
      }

      // Get the number of items from the API
      this.$store.dispatch('chartFieldAggregation', requestParams)
        .then(({ data }) => {
          this.value = data.value
        })
    }
  }
}
</script>

<style scoped>

</style>
