<template>
    <b-modal v-model="modalShown" :title="$t('components.sentiment_over_time.messages.event_details')">
        <div class="event-details" v-if="modalShown && eventData !== null">
            <!--Title row-->
            <div class="row">
                <div class="col">
                    <h5>{{ eventData.title }}</h5>
                </div>
            </div>

            <!--Date range row-->
            <div class="row">
                <div class="col">
                    <b-card bg-variant="light">
                        <div class="row">
                            <!--Calendar icon column-->
                            <div class="col-2 modal-calendar-icon text-secondary">
                                <i class="far fa-calendar-alt"></i>
                            </div>

                            <!--Dates column-->
                            <div class="col-10 no-padding">
                                <!--Start/end tags row-->
                                <div class="row">
                                    <div class="col">
                                        <b-badge variant="secondary">
                                            {{ $t('components.sentiment_over_time.messages.start') }}
                                        </b-badge>
                                    </div>
                                    <div class="col">
                                        <b-badge variant="secondary">
                                            {{ $t('components.sentiment_over_time.messages.end') }}
                                        </b-badge>
                                    </div>
                                </div>

                                <!--Dates row-->
                                <div class="row">
                                    <div class="col">
                                        {{ eventData.startDate }}
                                    </div>
                                    <div class="col">
                                        {{ eventData.endDate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>

            <!--Tabs for word cloud/articles list-->
            <div class="row tabs-row">
                <div class="col">
                    <b-tabs>
                        <!--Word cloud tab-->
                        <b-tab :title="$t('components.sentiment_over_time.messages.word_cloud')">
                            <event-word-cloud :event-id="eventData.id"></event-word-cloud>
                        </b-tab>

                        <!--Articles tab-->
                        <b-tab>
                            <template slot="title">
                                {{ $t('components.sentiment_over_time.messages.articles') }}
                                <b-badge>
                                    {{ eventData.articles.length }}
                                </b-badge>
                            </template>
                            <div class="documents-list-container">
                                <documents-list :items="eventArticles"
                                                :domain-cols="3">
                                </documents-list>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import EventWordCloud from './EventWordCloud'
import DocumentsList from '../../DocumentsList'

export default {
  name: 'EventDetailsModal',
  components: { DocumentsList, EventWordCloud },
  props: {
    showModal: Boolean,
    eventData: Object
  },
  watch: {
    showModal: function (showModal) {
      // Show the modal (if it's false, we don't have to do anything)
      if (showModal) {
        this.modalShown = showModal
      }
    },
    modalShown: function (modalShown) {
      if (!modalShown) {
        // Emit event to notify parent that the modal closed
        this.$emit('modalClosed')
      } else {
        // Modal is shown, get articles
        this.$store.dispatch('getEventArticles', this.eventData.id)
          .then(({ data }) => {
            this.eventArticles = data.data
          })
      }
    }
  },
  data: function () {
    return {
      modalShown: this.showModal,
      eventArticles: []
    }
  }
}
</script>

<style scoped>
    .modal-calendar-icon {
        font-size: 36px;
        padding-right: 5px;
        padding-left: 5px;
    }

    .no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    .event-details {
        text-align: center;
    }

    .documents-list-container {
        margin-top: 10px;
        max-height: 400px;
        overflow-y: auto;
    }

    .tabs-row {
        margin-top: 20px;
    }
</style>
