<template>
    <div class="sources-container">
        <b-row>
            <b-col>
                <h1>{{ $t('sources_overview.title') }}</h1>
            </b-col>
        </b-row>

        <!--Date picker-->
        <b-row>
            <b-col offset="2" cols="8">
                <date-range-selector @range-selected="onDateSelected"
                                     :initial-range="selectedDate">
                </date-range-selector>
            </b-col>
        </b-row>

        <!--Tabs-->
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-tabs :lazy="true" card>
                        <!--Summary tab-->
                        <b-tab :title="$t('dashboard.tabs.summary')" active>
                            <!--Number of articles/comments/tweets counts-->
                            <b-row class="counts-row">
                                <b-col>
                                    <!--Number of articles count-->
                                    <metric-display :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_articles')"
                                                    icon="fas fa-newspaper"
                                                    type="article"/>
                                </b-col>
                                <b-col>
                                    <!--Number of comments count-->
                                    <metric-display :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_comments')"
                                                    icon="fas fa-comments"
                                                    type="comment"/>
                                </b-col>
                                <b-col>
                                    <!--Number of tweets count-->
                                    <metric-display :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_tweets')"
                                                    icon="fab fa-twitter"
                                                    type="tweet"/>
                                </b-col>
                            </b-row>

                            <!--Number of domain count widgets-->
                            <b-row class="counts-row">
                                <b-col>
                                    <!--# of domains that have articles linked to entities-->
                                    <unique-count :date-range="selectedDate"
                                                  field="domain"
                                                  :title="$t('dashboard.charts.domains_with_articles')"
                                                  type="article"/>
                                </b-col>
                                <b-col>
                                    <!--# of domains that have comments linked to entities-->
                                    <unique-count :date-range="selectedDate"
                                                  field="domain"
                                                  :title="$t('dashboard.charts.domains_with_comments')"
                                                  type="comment"/>
                                </b-col>
                            </b-row>

                            <!--Articles/comments per type pies-->
                            <b-row>
                                <b-col>
                                    <overview-pie-per-type :date-range="selectedDate"
                                                           :type-label="$t('dashboard.tabs.articles')"
                                                           type="articles"
                                                           :height="400"/>
                                </b-col>
                                <b-col>
                                    <overview-pie-per-type :date-range="selectedDate"
                                                           :type-label="$t('dashboard.tabs.comments')"
                                                           type="comments"
                                                           :height="400"/>
                                </b-col>
                            </b-row>

                            <!--Articles/comments per domain donuts-->
                            <b-row>
                                <b-col>
                                    <overview-donut-per-domain :date-range="selectedDate"
                                                               :type-label="$t('dashboard.tabs.articles')"
                                                               type="articles"
                                                               :height="500"/>
                                </b-col>
                                <b-col>
                                    <overview-donut-per-domain :date-range="selectedDate"
                                                               :type-label="$t('dashboard.tabs.comments')"
                                                               type="comments"
                                                               :height="500"/>
                                </b-col>
                            </b-row>

                            <!--Top Twitter hashtags & mentions-->
                            <b-row>
                                <b-col>
                                    <!--Top hashtags-->
                                    <top-terms-bar :date-range="selectedDate"
                                                   :terms-type="$t('dashboard.charts.top_twitter_hashtags_series')"
                                                   :title="$t('dashboard.charts.top_twitter_hashtags')"
                                                   color="#3366AA"
                                                   :size="20"
                                                   index="tweets"
                                                   field="entities.hashtags.text"/>
                                </b-col>

                                <b-col>
                                    <!--Top mentions-->
                                    <top-terms-bar :date-range="selectedDate"
                                                   :terms-type="$t('dashboard.charts.top_twitter_mentions_series')"
                                                   :title="$t('dashboard.charts.top_twitter_mentions')"
                                                   color="#64aa00"
                                                   :size="50"
                                                   index="tweets"
                                                   field="entities.user_mentions.screen_name"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col offset="3" cols="6">
                                    <!--Top article tags-->
                                    <top-terms-bar :date-range="selectedDate"
                                                   :terms-type="$t('dashboard.charts.top_article_tags_series')"
                                                   :title="$t('dashboard.charts.top_article_tags')"
                                                   color="#7B435B"
                                                   :size="50"
                                                   index="articles"
                                                   field="tags"/>
                                </b-col>
                            </b-row>

                            <!--Sources table-->
                            <b-row>
                                <b-col offset="2" cols="8">
                                    <data-sources-table :date-range="selectedDate"/>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Articles tab-->
                        <b-tab :title="$t('dashboard.tabs.articles')">
                            <b-row>
                                <b-col>
                                    <!--Overview timeline-->
                                    <overview-timeline :date-range="selectedDate"
                                                       type="articles"
                                                       :type-label="$t('dashboard.tabs.articles')"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Number of articles count-->
                                    <metric-display :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_articles')"
                                                    icon="fas fa-newspaper"
                                                    type="article"/>
                                </b-col>
                                <b-col>
                                    <!--# of domains writing about the entity-->
                                    <unique-count :date-range="selectedDate"
                                                  field="domain"
                                                  :title="$t('dashboard.charts.unique_domains')"
                                                  type="article"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Graph of all articles vs ones linked to entities-->
                                    <all-vs-entity :date-range="selectedDate"
                                                   :title="$t('sources_overview.charts.all_vs_linked_articles')"
                                                   type="article"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Top domains bar chart-->
                                    <top-domains-bar :date-range="selectedDate"
                                                     type="article"/>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Comments tab-->
                        <b-tab :title="$t('dashboard.tabs.comments')">
                            <b-row>
                                <b-col>
                                    <!--Overview timeline-->
                                    <overview-timeline :date-range="selectedDate"
                                                       type="comments"
                                                       :type-label="$t('dashboard.tabs.comments')"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col offset="4" cols="4">
                                    <!--Number of comments count-->
                                    <metric-display :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_comments')"
                                                    icon="fas fa-comments"
                                                    type="comment"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Graph of all comments vs ones linked to entities-->
                                    <all-vs-entity :date-range="selectedDate"
                                                   :title="$t('sources_overview.charts.all_vs_linked_comments')"
                                                   type="comment"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Top domains bar chart-->
                                    <top-domains-bar :date-range="selectedDate"
                                                     type="comment"/>
                                </b-col>
                            </b-row>
                        </b-tab>

                        <!--Tweets tab-->
                        <b-tab :title="$t('dashboard.tabs.tweets')">
                            <b-row>
                                <b-col>
                                    <!--Overview timeline-->
                                    <overview-timeline :date-range="selectedDate"
                                                       type="tweets"
                                                       :type-label="$t('dashboard.tabs.tweets')"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col offset="4" cols="4">
                                    <!--Number of tweets count-->
                                    <metric-display :date-range="selectedDate"
                                                    :title="$t('dashboard.charts.number_of_tweets')"
                                                    icon="fab fa-twitter"
                                                    type="tweet"/>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <!--Graph of all tweets vs ones linked to entities-->
                                    <all-vs-entity :date-range="selectedDate"
                                                   :title="$t('sources_overview.charts.all_vs_linked_tweets')"
                                                   type="tweet"/>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import MetricDisplay from '../components/charts/MetricDisplay'
import UniqueCount from '../components/charts/UniqueCount'
import AllVsEntity from '../components/charts/AllVsEntity'
import TopDomainsBar from '../components/charts/TopDomainsBar'
import DateRangeSelector from '../components/input/DateRangeSelector'
import OverviewTimeline from '../components/charts/OverviewTimeline'
import OverviewPiePerType from '../components/charts/OverviewPiePerType'
import OverviewDonutPerDomain from '../components/charts/OverviewDonutPerDomain'
import TopTermsBar from '../components/charts/TopTermsBar'
import DataSourcesTable from '../components/charts/DataSourcesTable'

export default {
  name: 'SourcesOverview',
  components: {
    DataSourcesTable,
    TopTermsBar,
    OverviewDonutPerDomain,
    OverviewPiePerType,
    OverviewTimeline,
    DateRangeSelector,
    TopDomainsBar,
    AllVsEntity,
    UniqueCount,
    MetricDisplay
  },
  props: ['entity'],
  data () {
    return {
      selectedDate: this.$store.getters.lastWeekDateRange
    }
  },
  methods: {
    onDateSelected: function (dateRange) {
      // Save the date range
      this.selectedDate = dateRange
    }
  }
}
</script>

<style scoped>
    /* Make counts for comments/articles/tweets fit */
    .counts-row > .col {
        padding-left: 5px;
        padding-right: 5px;
    }

    /* Spaces between rows */
    .row {
        margin-top: 15px;
    }

    .row:first-child {
        margin-top: 0;
    }
</style>
