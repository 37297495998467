<template>
  <div class="sentiment-over-time-container">
    <b-row>
      <b-col>
        <!--Chart-->
        <highcharts :callback="chartCreated"
                    :options="chartOptions">
        </highcharts>
      </b-col>
    </b-row>

    <!--Too many requests for sentiment items warning-->
    <b-row class="too-many-requests-alert" v-if="sentimentItems.tooManyRequests">
      <b-col offset="2" cols="8">
        <!--suppress HtmlUnknownBooleanAttribute -->
        <b-alert variant="warning"
                 @dismissed="sentimentItems.tooManyRequests = false"
                 show dismissible>
          <i class="fas fa-hourglass-half fa-spin"></i>&nbsp;
          {{ $t('components.sentiment_over_time.messages.too_many_items_requests') }}
        </b-alert>
      </b-col>
    </b-row>

    <!--Sentiment items-->
    <b-row class="sentiment-items-container" v-if="sentimentItems.show">
      <b-col>
        <sentiment-items-list :items="sentimentItems.items"
                              :date-range="sentimentItems.dateRange"
                              :show-tab="sentimentItems.tab"
                              @close="closeSentimentItems">
        </sentiment-items-list>
      </b-col>
    </b-row>

    <!--Event Details modal-->
    <event-details-modal :event-data="eventData"
                         :show-modal="showModal"
                         @modalClosed="showModal = false">
    </event-details-modal>
  </div>
</template>

<script>
import EventDetailsModal from './EventDetailsModal'
import SentimentItemsList from './SentimentItemsList'

export default {
  name: 'SentimentOverTime',
  components: { SentimentItemsList, EventDetailsModal },
  props: {
    entityId: String,
    dateRange: Object,
    type: String,
    filters: Object
  },
  data () {
    // noinspection JSUnresolvedFunction
    return {
      showModal: false,
      eventData: null,
      chartInstance: null,
      bucketInterval: null,
      sentimentItems: {
        tooManyRequests: false,
        show: false,
        items: null,
        tab: null,
        dateRange: {
          // We need to display the date range of the requested items
          start: null,
          end: null
        }
      },
      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        series: [],
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.type[0].toUpperCase() + this.type.substring(1) + 's'
          }
        },
        tooltip: {
          shared: true
        },
        navigator: {
          enabled: true,
          xAxis: {} // Used to set min/max manually later
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: this.showItemsOfBucket
              }
            }
          }
        }
      }
    }
  },
  methods: {
    chartCreated: function (chartInstance) {
      this.chartInstance = chartInstance
    },
    closeSentimentItems: function () {
      // Hide the sentiment items box
      this.sentimentItems.show = false

      // Delete the sentiment items that were shown
      this.sentimentItems.items = null
    },
    showItemsOfBucket: function (clickEvent) {
      // Get x position of clicked item
      const bucketX = clickEvent.point.x
      const clickedSeries = clickEvent.point.series.name

      // Find limits of bucket
      const dateStart = bucketX
      let dateEnd
      if (this._.isInteger(this.bucketInterval)) {
        // Add interval to the start date of the bucket
        dateEnd = dateStart + this.bucketInterval
      } else {
        // Use interval from ES response (should happen only when we have 1 bucket)
        dateEnd = bucketX + '||+' + this.bucketInterval
      }

      // Create request params
      const requestParams = {
        date_start: dateStart,
        date_end: dateEnd,
        entity_id: this.entityId,
        type: this.type
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestParams, this.filters)
      }

      // Make the request to get the items
      this.$store.dispatch('getSentimentItems', requestParams)
        .then(({ data }) => {
          // Remove too many requests alert in case it is shown
          this.sentimentItems.tooManyRequests = false

          // Add the sentiment items to the list of items
          this.sentimentItems.items = data.items

          // Get the date range of the items
          // noinspection JSUnresolvedVariable
          this.sentimentItems.dateRange.start = new Date(data.date_range.start)
          // noinspection JSUnresolvedVariable
          this.sentimentItems.dateRange.end = new Date(data.date_range.end)

          // Show the items
          this.sentimentItems.tab = clickedSeries
          this.sentimentItems.show = true
        })
        .catch(err => {
          if (err.response.status === 429) {
            // Show too many requests warning on page
            this.sentimentItems.tooManyRequests = true

            // Reset shown tab
            this.sentimentItems.tab = null

            // Hide sentiment items box
            this.closeSentimentItems()
          }
        })
    },
    plotBandClick: function (eventData) {
      // Add formatted dates as strings to the event's data
      // noinspection JSCheckFunctionSignatures
      eventData.startDate = new Date(eventData.dateRange.gte).toLocaleString()
      // noinspection JSCheckFunctionSignatures
      eventData.endDate = new Date(eventData.dateRange.lte).toLocaleString()

      // Add data of this event to the model
      this.eventData = eventData

      // Show the modal
      this.showModal = true
    },
    setChartTitle: function () {
      this.chartOptions.title.text = this.$t('components.sentiment_over_time.messages.sentiment_of') + ' ' + this.type + this.$t('components.sentiment_over_time.messages.over_time')
    },
    updateChart: function () {
      // Create request parameters
      const requestParams = {
        date_start: this.dateRange.start,
        date_end: this.dateRange.end,
        type: this.type,
        entity_id: this.entityId
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestParams, this.filters)
      }

      // Get data from API and display it
      this.$store.dispatch('chartSentimentOverTime', requestParams)
        .then(({ data }) => {
          // Get bucket interval
          this.bucketInterval = data.bucket_interval

          // Reset Highcharts series
          this.chartOptions.series = data.series

          // Set the chart's start/end X axis values
          const max = new Date(requestParams.date_end)
          const min = new Date(requestParams.date_start)
          min.setHours(0, 0, 0, 0)

          this.chartOptions.xAxis.min = this.chartOptions.navigator.xAxis.min = min.getTime()
          this.chartOptions.xAxis.max = this.chartOptions.navigator.xAxis.max = max.getTime()
        })

      // Check if we should add events to the chart (only for articles)
      if (this.type === 'article') {
        this.$store.dispatch('getEvents', {
          entity_id: this.entityId,
          date_start: this.dateRange.start,
          date_end: this.dateRange.end
        }).then(({ data }) => {
          let colorIndex = 0

          // Add events to the chart
          // noinspection JSUnresolvedVariable
          this._.each(data.hits, item => {
            // Get the event's data

            // Check that dateRange exists (should always exist because of the query...)
            if (this._.has(item, 'dateRange')) {
              // noinspection JSCheckFunctionSignatures
              const startDate = new Date(item.dateRange.gte)
              // noinspection JSCheckFunctionSignatures
              const endDate = new Date(item.dateRange.lte)

              // Check that the chart hasn't been destroyed
              if (!this._.has(this.chartInstance, 'xAxis')) {
                return false
              }

              // Add plot band from start to end date
              this.chartInstance.xAxis[0].addPlotBand({
                from: startDate,
                to: endDate,
                color: this.colors[colorIndex % this.colors.length], // Mod to get the first color again if we run out
                id: 'band-' + startDate + '-' + endDate,
                events: {
                  click: () => {
                    // Call the real click handler function with the event's data as a parameter
                    // (not accessible from Highcharts' click event)
                    this.plotBandClick(item)
                  }
                }
              })

              // Get the next color next time
              colorIndex++
            }
          })
        })
      }
    }
  },
  watch: {
    dateRange: function () {
      // Date range changed, update the chart
      this.updateChart()
    },
    filters: function () {
      // Filters changed, update the chart
      this.updateChart()
    },
    '$i18n.locale': function () {
      // Update chart title when locale changes
      this.setChartTitle()
    }
  },
  mounted () {
    this.updateChart()
    this.setChartTitle()
  }
}
</script>

<style scoped>
.sentiment-over-time-container {
  text-align: left;
}

/*Sentiment items box animation*/
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sentiment-items-container {
  animation: slide-up 0.4s ease;
}

/*Too many requests alert animation*/
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.too-many-requests-alert {
  animation: slide-down 0.4s ease;
}
</style>
