<template>
    <highcharts :options="chartOptions"></highcharts>
</template>

<script>
export default {
  name: 'OverviewDonutPerDomain',
  props: {
    dateRange: Object,
    type: String,
    typeLabel: String,
    height: Number
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          height: this.height
        },
        title: {
          text: ''
        },
        series: [],
        tooltip: {
          pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
              '<b>{point.y}</b> ({point.percentage:.1f} %) <br/>'
        }
      }
    }
  },
  methods: {
    updateChart: function () {
      // Call the API to get new data
      this.$store.dispatch('chartCountPerDomain', {
        type: this.type,
        params: {
          date_start: this.dateRange.start,
          date_end: this.dateRange.end
        }
      }).then(({ data }) => {
        // Create series with new data
        this.chartOptions.series = [{
          name: this.typeLabel,
          data: data.items,
          showInLegend: true,
          innerSize: '60%'
        }]
      })
    },
    setChartTitle: function () {
      this.chartOptions.title.text = this.typeLabel + ' ' + this.$t('components.overview_donut.per_domain')
    }
  },
  watch: {
    dateRange: function (newRange) {
      // Date range changed, update the chart
      this.updateChart()
    },
    '$i18n.locale': function () {
      // Update chart title when locale changes
      this.setChartTitle()
    }
  },
  mounted () {
    this.updateChart()
    this.setChartTitle()
  }
}
</script>

<style scoped>

</style>
