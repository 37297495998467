<template>
    <div class="msl-multi-select">
        <SearchableList
                :list-items="availableOptions"
                :no-options-text="noOptionsText"
                :no-items-found-text="noOptionsFoundText"
                :selected-list-items="selectedItems"
                :display-property="reduceDisplayProperty"
                :value-property="reduceValueProperty"
                :placeholder-text="searchOptionsPlaceholder"
                :search-input-class="searchInputClass"
                class="msl-multi-select__list"
                @on-click-option="onOptionSelect"
        />

        <div class="msl-multi-select__actions">
            <a  class="msl-multi-select__action msl-multi-select__action-select-all"
                :class="{'invisible': !showSelectAllButtons}"
                @click="onSelectAllOptions">
                <i class="fas fa-angle-double-right"></i>
            </a>

            <i class="fas fa-exchange-alt multi-select__action-icon"></i>

            <a  class="msl-multi-select__action msl-multi-select__action-unselect-all"
                :class="{'invisible': !showSelectAllButtons}"
                @click="onUnselectAllOptions">
                <i class="fas fa-angle-double-left"></i>
            </a>
        </div>

        <SearchableList
                :list-items="selectedItems"
                :no-options-text="selectedNoOptionsText"
                :no-items-found-text="noSelectedOptionsFoundText"
                :display-property="reduceDisplayProperty"
                :value-property="reduceValueProperty"
                :placeholder-text="selectedOptionsPlaceholder"
                :search-input-class="searchInputClass"
                class="msl-multi-select__selected msl-multi-select__list"
                @on-click-option="onOptionRemove"
        />
    </div>
</template>

<script>
// Source: https://github.com/abhimediratta/vue-multiselect-listbox
import SearchableList from './SearchableList'

function getSelectedItemsFromValue (values, valueProperty, availableOptions) {
  if (valueProperty) {
    const selectedItems = []
    values.forEach((value) => {
      const item = availableOptions.find(function findOptions (option) {
        return option[valueProperty] === value
      })
      selectedItems.push(item)
    })
    return selectedItems
  }
  return [...values]
}

function getValueFromOption (valueProperty, option) {
  return valueProperty ? option[valueProperty] : option
}

function getValuesFromOptions (valueProperty, options) {
  const values = []
  options.forEach((option) => {
    values.push(getValueFromOption(valueProperty, option))
  })
  return values
}

export default {
  name: 'MultiSelect',
  components: {
    SearchableList
  },
  model: {
    prop: 'value'
  },
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    searchOptionsPlaceholder: {
      type: String,
      default () {
        return 'Search'
      }
    },
    selectedOptionsPlaceholder: {
      type: String,
      default () {
        return 'Search'
      }
    },
    reduceDisplayProperty: {
      type: Function,
      default: (value) => value
    },
    reduceValueProperty: {
      type: Function,
      default: (value) => value
    },
    noOptionsText: {
      type: String,
      default: ''
    },
    selectedNoOptionsText: {
      type: String,
      default: 'Select'
    },
    noOptionsFoundText: {
      type: String,
      default: 'No options found'
    },
    noSelectedOptionsFoundText: {
      type: String,
      default: 'No options found'
    },
    showSelectAllButtons: {
      type: Boolean,
      default: true
    },
    searchInputClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedItems: getSelectedItemsFromValue(this.value, this.valueProperty, this.options)
    }
  },
  computed: {
    availableOptions () {
      if (!this.value || !this.value.length) {
        return [...this.options]
      }
      return this.options.filter((option) => {
        if (this.valueProperty) {
          return this.value.indexOf(option[this.valueProperty]) < 0
        }
        return !this.value.find((value) => value === option)
      })
    }
  },
  methods: {
    onOptionSelect (option) {
      this.selectedItems.push(option)
      const items = [...this.value, getValueFromOption(this.valueProperty, option)]
      this.$emit('input', items)
      this.$emit('change', items)
    },
    onOptionRemove (option) {
      const items = [...this.value]
      const { selectedItems } = this
      let valueIndex = items.findIndex((item) => {
        if (this.valueProperty) {
          return item && option && (item === option[this.valueProperty])
        }
        return item === option
      })
      items.splice(valueIndex, 1)
      valueIndex = selectedItems.findIndex((item) => {
        if (this.valueProperty) {
          return item && option && (item[this.valueProperty] === option[this.valueProperty])
        }
        return item === option
      })
      selectedItems.splice(valueIndex, 1)
      // Copy the array because Vue doesn't react on the array modification by lodash
      // https://vuejs.org/v2/guide/list.html#Array-Change-Detection
      this.selectedItems = [...selectedItems]
      this.$emit('input', items)
      this.$emit('change', items)
    },
    onSelectAllOptions () {
      this.selectedItems = [...this.options]
      const selectedValues = getValuesFromOptions(this.valueProperty, this.options)
      this.$emit('input', selectedValues)
      this.$emit('change', selectedValues)
    },
    onUnselectAllOptions () {
      this.selectedItems = []
      this.$emit('input', [])
      this.$emit('change', [])
    }
  },
  watch: {
    value: function () {
      // Set selected items when the v-model value changes
      this.selectedItems = getSelectedItemsFromValue(this.value, this.valueProperty, this.options)
    }
  }
}
</script>

<style lang="scss">
    @import '../../scss/vue-multi-select-listbox.scss';
</style>
