import Vue from 'vue'
import { i18n } from './plugins/i18n'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import Colors from './plugins/colors'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store'
import VueRangedatePicker from 'vue-rangedate-picker-winslow'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import wordcloudInit from 'highcharts/modules/wordcloud'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueClipboard from 'vue-clipboard2'
import vueCustomElement from 'vue-custom-element'

// Setup VueClipboard options
VueClipboard.config.autoSetContainer = true

// Register Highstock module with Highcharts
stockInit(Highcharts)
wordcloudInit(Highcharts)

// Register other libraries with Vue
Vue.use(Colors)
Vue.use(vueCustomElement)
Vue.use(HighchartsVue)
Vue.use(VueClipboard)
Vue.use(BootstrapVue)
Vue.use(VueSidebarMenu)
Vue.use(VueLodash, { lodash: lodash })
Vue.component('vue-rangedate-picker', VueRangedatePicker)

Vue.config.productionTip = false

// Setup app options
App.store = store
App.router = router
App.i18n = i18n
Vue.render = h => h(App)

// Create the app's custom element
Vue.customElement('social-web-observatory', App)
