<template>
    <b-popover :show.sync="popoverShow"
               :target="target"
               :title="title"
               @shown="updateEntitiesList"
               triggers="click blur"
               placement="auto">

        <!--Public entities checkbox-->
        <div class="row">
            <div class="col">
                <b-checkbox v-model="showPublicEntities"
                            class="public-checkbox"
                            @input="updateEntitiesList"
                            :switch="true">
                    {{ $t('my_entities.buttons.show_public') }}
                </b-checkbox>
            </div>
        </div>

        <!--Entity category tabs and list-->
        <div class="row entity-selection-row">
            <div class="col">
                <b-tabs small pills :lazy="true" v-model="tabIndex">
                    <b-tab v-for="entityType in entities" :key="entityType.type">
                        <!--Tab title-->
                        <template slot="title">
                            <!--Entity type icon-->
                            <i class="fas" :class="entityType.icon"></i>

                            <!--Entity type title-->
                            {{ entityType.type }}

                            <!--Entity count-->
                            <b-badge>{{ entityType.entitiesCount }}</b-badge>
                        </template>

                        <!--Entities list-->
                        <b-list-group class="entities-list list-group-left">
                            <b-list-group-item v-for="item in entityType.items"
                                               @click.self="selectEntity(item)"
                                               :key="item['_id']">
                                <!--Entity name-->
                                {{ item['_source'].name }}
                            </b-list-group-item>
                        </b-list-group>

                        <!--Pagination controls-->
                        <b-row v-if="entityType.entitiesCount > pageSize" class="mt-2">
                            <b-col>
                                <!--suppress HtmlDeprecatedAttribute -->
                                <b-pagination :total-rows="entityType.entitiesCount"
                                              v-model="entityType.currentPage"
                                              :per-page="pageSize"
                                              align="center"
                                              @input="fetchEntities(entityType.currentPage, entityType.type, showPublicEntities)">
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </b-popover>
</template>

<script>
export default {
  name: 'EntitySelectionPopover',
  props: {
    target: String,
    title: String,
    extraData: Object
  },
  data () {
    return {
      pageSize: this.$store.getters.userSettings.pageSize,
      currentPage: 0,
      tabIndex: parseInt(localStorage.entityTypeTab) || 0,
      showPublicEntities: this.$store.getters.getPublicEntitiesValue,
      popoverShow: false,
      entities: this.$store.getters.entityTypes
    }
  },
  methods: {
    selectEntity (entity) {
      this.popoverShow = false

      // Create data object
      const data = {
        entity: entity
      }

      // If there is any extra data that we should include in the emitted event, add it to the object
      if (!this._.isUndefined(this.extraData)) {
        this._.assign(data, this.extraData)
      }
      this.$emit('entitySelected', data)
    },
    updateEntitiesList () {
      // See whether we should show the public entities or not (and copy its value so it doesn't change while requests
      // are being done)
      const publicEntities = this.showPublicEntities

      // Get the number of documents
      this.$store.dispatch('getEntitiesNumber', {
        public: publicEntities
      }).then(({ data }) => {
        // Get the entity counts per type from the response
        const entitiesPerType = data.counts

        // Add the counts to the entity type tabs
        this._.each(this.entities, (entityType) => {
          // Add the entity count to this entity type
          entityType.entitiesCount = entitiesPerType[entityType.type]

          // Fetch entities for this entity type
          this.fetchEntities(entityType.currentPage, entityType.type, publicEntities)
        })
      })
    },
    fetchEntities (newPage, category, publicEntities) {
      // Get the entity category's data
      const entityType = this._.find(this.entities, { type: category })

      // Get the number of entities the page should start from
      const pageNumber = newPage || entityType.currentPage
      let start = Math.max(pageNumber - 1, 0) * this.pageSize

      // If the current page has no more items (e.g. when deleting entities or changing page size) go to the first page
      if (start >= entityType.entitiesCount) {
        start = 0
        entityType.currentPage = 1
      }

      // Get entities for the selected page and show them in the page
      this.$store.dispatch('fetchEntities', {
        start: start,
        rows: this.pageSize,
        category: category,
        public: publicEntities
      }).then(({ data }) => {
        entityType.items = data.hits.hits
      }).catch((err) => {
        this.errorMsg.msg = err.toString()
      })
    }
  }
}
</script>

<style scoped>
    .entities-list {
        margin-left: -12px;
        margin-right: -12px;
        height: 300px;
        overflow: auto;
    }
</style>
