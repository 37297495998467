<template>
    <div id='timeline-embed' style="width: 100%; height: 600px">
    </div>
</template>

<script>
export default {
  name: 'EventTimeline',
  props: {
    dateRange: Object,
    entityId: String,
    entityName: String
  },
  mounted () {
    this.$store.dispatch('getEvents', {
      entity_id: this.entityId,
      date_start: this.dateRange.start,
      date_end: this.dateRange.end
    }).then(({ data }) => {
      let colorIndex = 0

      // Get list of events formatted for the timeline
      // noinspection JSUnresolvedVariable
      const events = this._.map(data.hits, evt => {
        // noinspection JSCheckFunctionSignatures
        const startDate = new Date(evt.dateRange.gte)
        // noinspection JSCheckFunctionSignatures
        const endDate = new Date(evt.dateRange.lte)

        // Get the next color
        colorIndex++

        // noinspection JSUnresolvedVariable
        return {
          text: {
            headline: evt.title,
            // todo: fix "s" in the end of articleS/entitieS
            text: evt.articles.length + ' ' + this.$t('components.event_timeline.related_articles') + ', ' + evt.hasEntities.length + ' ' + this.$t('components.event_timeline.related_entities') + '.'
          },
          start_date: {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate(),
            hour: startDate.getHours(),
            minute: startDate.getMinutes()
          },
          end_date: {
            year: endDate.getFullYear(),
            month: endDate.getMonth() + 1,
            day: endDate.getDate(),
            hour: endDate.getHours(),
            minute: endDate.getMinutes()
          },
          background: {
            color: this.colors[colorIndex % this.colors.length]
          }
        }
      })

      // Get date range as Date objects
      const rangeStart = new Date(this.dateRange.start).toLocaleString()
      const rangeEnd = new Date(this.dateRange.end).toLocaleString()

      // Create full timeline data
      const timelineData = {
        title: {
          text: {
            headline: this.$t('components.event_timeline.events_related_to') + ' ' + this.entityName,
            text: '<p>' + this.$t('components.event_timeline.between') + ' ' + rangeStart + ' ' + this.$t('components.event_timeline.and') + ' ' + rangeEnd + '</p>'
          }
        },
        events: events
      }

      // Create the timeline
      if ('TL' in window) {
        window.timeline = new window.TL.Timeline('timeline-embed', timelineData)
      }
    })
  }
}
</script>

<style scoped>

</style>
