<template>
    <div>
        <!--Title-->
        <b-row>
            <b-col>
                <h2>{{ $t('organize_sources.title_new_group') }}</h2>
            </b-col>
        </b-row>

        <!--Source group editor-->
        <b-row>
            <b-col>
                <source-group-editor/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SourceGroupEditor from '../../components/SourceGroupEditor'

export default {
  name: 'NewSourceGroup.vue',
  components: { SourceGroupEditor }
}
</script>

<style scoped>

</style>
