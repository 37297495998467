<template>
    <b-card class="selector-card">
        <div class="row">
            <div class="col date-range-title-col">
                <h5>
                    <i class="far fa-calendar-alt"></i>
                    {{ $t('components.date_range_selector.date_range') }}
                </h5>
            </div>
            <div class="col">
                <vue-rangedate-picker @selected="onDateSelected"
                                      :init-range="initialRange"
                                      :short-days="$t('components.date_range_selector.short_days')"
                                      :months="$t('components.date_range_selector.months')"
                                      :captions="captions"
                                      :preset-ranges="presetRanges"
                                      format="DD MMM YYYY"
                                      righttoleft="true"
                                      i18n="EN">
                </vue-rangedate-picker>
            </div>
        </div>
    </b-card>
</template>

<script>
import { i18n } from '../../plugins/i18n'

export default {
  name: 'DateRangeSelector',
  props: {
    initialRange: Object
  },
  data () {
    return {
      captions: {
        title: i18n.t('components.date_range_selector.choose_dates'),
        ok_button: i18n.t('components.date_range_selector.buttons.apply')
      },
      presetRanges: {
        today: function () {
          const n = new Date()
          const startToday = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1, 0, 0)
          const endToday = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1, 23, 59)
          return {
            label: i18n.t('components.date_range_selector.buttons.today'),
            active: false,
            dateRange: {
              start: startToday,
              end: endToday
            }
          }
        },
        last7days: function () {
          const n = new Date()
          const start = new Date(n.getFullYear(), n.getMonth(), n.getDate() - 5)
          const end = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1)
          return {
            label: i18n.t('components.date_range_selector.buttons.last_7_days'),
            active: false,
            dateRange: {
              start: start,
              end: end
            }
          }
        },
        last30days: function () {
          const n = new Date()
          const start = new Date(n.getFullYear(), n.getMonth(), n.getDate() - 29)
          const end = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1)
          return {
            label: i18n.t('components.date_range_selector.buttons.last_30_days'),
            active: false,
            dateRange: {
              start: start,
              end: end
            }
          }
        },
        thisMonth: function () {
          const n = new Date()
          const startMonth = new Date(n.getFullYear(), n.getMonth(), 2)
          const endMonth = new Date(n.getFullYear(), n.getMonth() + 1, 1)
          return {
            label: i18n.t('components.date_range_selector.buttons.this_month'),
            active: false,
            dateRange: {
              start: startMonth,
              end: endMonth
            }
          }
        },
        lastMonth: function () {
          const n = new Date()
          const startMonth = new Date(n.getFullYear(), n.getMonth() - 1, 2)
          const endMonth = new Date(n.getFullYear(), n.getMonth(), 1)
          return {
            label: i18n.t('components.date_range_selector.buttons.last_month'),
            active: false,
            dateRange: {
              start: startMonth,
              end: endMonth
            }
          }
        }
      }
    }
  },
  methods: {
    onDateSelected: function (newDateRange) {
      // Emit event with new date range
      this.$emit('range-selected', newDateRange)
    }
  }
}
</script>

<style>
    /* Make rangedate picker's title smaller */
    .calendar > .calendar-head > h2 {
        font-size: 24px;
    }

    .calendar > .calendar-head > i.close {
        margin-right: 15px;
    }

    div.calendar-root > div.input-date {
        border-radius: 4px;
        background-color: white;
    }

    li.calendar_preset-ranges {
        border-radius: 4px;
    }

    button.calendar-btn-apply {
        border-radius: 4px;
    }
</style>

<style scoped>
    .date-range-title-col > h5 {
        margin-bottom: 0;
        line-height: 33px;
    }

    .selector-card {
        background-color: #4396ff;
    }
</style>
