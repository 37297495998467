<template>
    <b-row v-if="errorMsg.msg.length > 0">
        <b-col>
            <b-alert variant="warning" show dismissible @dismissed="errorMsg.msg = ''">
                {{ errorMsg.msg }}
            </b-alert>
        </b-col>
    </b-row>
</template>

<script>
export default {
  name: 'WarningMessage',
  props: {
    errorMsg: Object
  }
}
</script>

<style scoped>

</style>
