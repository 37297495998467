<template>
    <highcharts :options="chartOptions"></highcharts>
</template>

<script>
export default {
  name: 'TopDomainsBar',
  props: {
    entityId: String,
    dateRange: Object,
    type: String,
    filters: Object
  },
  data () {
    // noinspection JSUnresolvedFunction
    return {
      chartOptions: {
        chart: {
          type: 'bar'
        },
        title: {
          text: ''
        },
        series: [],
        xAxis: {
          categories: [],
          max: 15,
          scrollbar: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: this.type[0].toUpperCase() + this.type.substring(1) + 's'
          }
        },
        legend: {
          enabled: false
        }
      }
    }
  },
  methods: {
    updateChart: function () {
      // Create request parameters
      const requestData = {
        entity_id: this.entityId,
        type: this.type,
        date_start: this.dateRange.start,
        date_end: this.dateRange.end,
        index: 'sentiment',
        field: 'domain',
        size: 100
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestData, this.filters)
      }

      this.$store.dispatch('chartTermsAggregation', requestData)
        .then(({ data }) => {
          const buckets = data.terms

          this.chartOptions.xAxis.scrollbar.enabled = (buckets.length > 15)
          this.chartOptions.xAxis.max = (buckets.length > 15) ? 15 : null

          // Update chart categories
          this.chartOptions.xAxis.categories = this._.map(buckets, 0)

          // Add new array of series with a new series
          // noinspection JSUnresolvedFunction
          this.chartOptions.series = [{
            name: this.type[0].toUpperCase() + this.type.substring(1) + 's',
            data: this._.map(buckets, 1)
          }]
        })
    },
    setChartTitle: function () {
      this.chartOptions.title.text = this.$t('components.top_domains_bar.title_start') + ' ' + this.type + 's' +
          (this._.isUndefined(this.entityId) ? '' : ' ' + this.$t('components.top_domains_bar.title_end'))
    }
  },
  watch: {
    dateRange: function (newRange) {
      // Date range changed, update the chart
      this.updateChart()
    },
    filters: function () {
      // Update the chart if the filters change
      this.updateChart()
    },
    '$i18n.locale': function () {
      // Update chart title when locale changes
      this.setChartTitle()
    }
  },
  mounted () {
    this.updateChart()
    this.setChartTitle()
  }
}
</script>

<style scoped>

</style>
