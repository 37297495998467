<template>
    <b-card class="item-card" :style="{'background-color': data['background_color']}">
        <div class="item-card-body d-flex flex-grow-1 flex-column">
            <div class="p-0">
                <div class="p-0 title-with-icon-container">
                    <!--App icon-->
                    <div class="icon-container">
                        <img class="app-icon" alt="Marketplace item icon" :src="data['icon_url']"/>
                    </div>

                    <!--App title-->
                    <div class="title-container">
                        <h4>{{ data.title }}</h4>
                    </div>
                </div>
            </div>

            <!-- App image -->
            <div class="p-0">
                <img class="app-image" alt="Marketplace item image" :src="data['image_url']">
            </div>

            <div class="p-0">
                <p class="item-description">{{ data.description }}</p>
            </div>

            <div class="p-0 d-flex flex-grow-1 button-row">
                <!--External tools-->
                <b-btn v-if="isExternal"
                       :href="data.path" variant="primary">
                    <i class="fas fa-external-link-alt"></i>
                    {{ $t('tools.buttons.go') }}
                </b-btn>

                <!--Go button for items that don't require entity selection-->
                <b-btn v-if="!requiresEntity && (!requiresLogin || (requiresLogin && isLoggedIn)) && !isExternal"
                       :to="data.path" variant="primary">
                    <i class="fas fa-external-link-alt"></i>
                    {{ $t('tools.buttons.go') }}
                </b-btn>

                <!--Go button for items that require entity selection-->
                <b-btn v-if="requiresEntity && isLoggedIn"
                       :id="'go_btn_' + data.path"
                       variant="primary">
                    <i class="fas fa-external-link-alt"></i>
                    {{ $t('tools.buttons.go') }}
                </b-btn>

                <!--"App requires login" tip-->
                <!--suppress HtmlUnknownBooleanAttribute -->
                <b-alert v-if="(requiresEntity || requiresLogin) && !isLoggedIn"
                         variant="secondary" class="login-alert" show>
                    {{ $t('tools.messages.this_item_requires') }}
                    <router-link to="login">{{ $t('tools.messages.login') }}</router-link>
                    !
                </b-alert>

                <!--Entity selection popover-->
                <entity-selection-popover v-if="requiresEntity && isLoggedIn"
                                          :extra-data="{ 'path': data.path }"
                                          :title="$t('tools.messages.select_entity_for') + ' ' + data.title"
                                          @entitySelected="launchAppWithEntity"
                                          :target="'go_btn_' + data.path">
                </entity-selection-popover>
            </div>
        </div>
    </b-card>
</template>

<script>
import EntitySelectionPopover from './EntitySelectionPopover'

export default {
  name: 'MarketplaceItem',
  components: { EntitySelectionPopover },
  props: {
    data: Object
  },
  methods: {
    launchAppWithEntity (data) {
      const entity = data.entity
      const path = data.path

      // Go to the given path with this entity
      this.$router.push({
        name: path,
        params: {
          id: entity._id
        }
      })
    }
  },
  computed: {
    requiresEntity: function () {
      // noinspection JSUnresolvedVariable
      return this.data.requires_entity
    },
    requiresLogin: function () {
      // noinspection JSUnresolvedVariable
      return this.data.requires_login
    },
    isExternal: function () {
      return this.data.external
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated
    }
  }
}
</script>

<style scoped>
    .login-alert {
        margin-bottom: 0;
    }

    .item-card, .item-card-body {
        height: 100%;
    }

    .button-row {
        align-items: flex-end;
        justify-content: center;
    }

    .title-with-icon-container {
        display: flex;
        align-items: center;
    }

    .title-with-icon-container > div {
        width: 50%;
        padding: 10px;
    }

    /*Limit the icon container's width*/
    .icon-container {
        max-width: 80px;
    }

    /*Allow title to take up remaining width*/
    .title-container {
        flex-grow: 1;
    }

    /*Set the app icon's width*/
    .app-icon {
        width: 60px;
    }

    /*Justify description text*/
    .item-description {
        text-align: justify;
    }

    /*Limit the image's width and add shadow*/
    .app-image {
        max-width: 100%;
        margin-bottom: 20px;

        -webkit-box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
    }
</style>
