<template>
    <div id="app" :class="{'collapsed' : collapsed, 'no-sidebar': !showSidebar}">
        <!--Sidebar menu-->
        <sidebar-menu v-if="showSidebar"
                      :collapsed="collapsed"
                      class="sidebar-menu"
                      width="250px"
                      @toggle-collapse="onCollapse"
                      :menu="menu">
        </sidebar-menu>

        <b-container class="mt-2">
            <router-view @authenticated="isAuthenticated"/>
        </b-container>
    </div>
</template>

<script>
import SidebarTitle from './components/SidebarTitle'
import LanguageSwitcher from './components/LanguageSwitcher'

export default {
  name: 'App',
  props: {
    defaultRoute: String
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    showSidebar () {
      // Return false for the "no sidebar" routes, defined in the data below
      return !this.noSidebarRoutes.includes(this.$route.name)
    }
  },
  data () {
    return {
      collapsed: false,
      noSidebarRoutes: [
        'ahedd-technology',
        'ahedd-innovation-business',
        'ahedd-social-legal-data',
        'ahedd-analytics',
        'ahedd-covid'
      ],
      menu: this.getInitialMenuItems()
    }
  },
  watch: {
    isAuthenticated: function () {
      // When authentication status changes, update the sidebar menu
      this.updateMenu()
    },
    '$i18n.locale': function () {
      // Reset the menu items when locale changes, in order to show translated menu options
      this.menu = this.getInitialMenuItems()

      // Request tools to add to the (newly re-created) sidebar menu
      this.addToolsToSidebar()

      // Update the last item of the menu based on whether the user is logged in or not
      this.updateMenu()
    }
  },
  methods: {
    getInitialMenuItems () {
      // Return new instances of menu items
      return [
        {
          header: true,
          title: this.$t('sidebar.app_title'),
          component: SidebarTitle,
          hiddenOnCollapse: true
        },
        {
          header: true,
          title: this.$t('sidebar.language_switcher'),
          component: LanguageSwitcher,
          hiddenOnCollapse: true
        },
        {
          href: '/about',
          title: this.$t('about.title'),
          icon: 'fa fa-info'
        },
        {
          href: '/tools',
          title: this.$t('tools.title'),
          icon: 'fas fa-toolbox',
          child: []
        },
        {
          href: '/entities',
          title: this.$t('my_entities.title'),
          icon: 'fa fa-user'
        },
        {
          href: '/sources',
          title: this.$t('sources_overview.title'),
          icon: 'fas fa-newspaper'
        },
        {
          href: '/organize-sources',
          title: this.$t('organize_sources.title'),
          icon: 'fas fa-columns'
        },
        {
          href: '/settings',
          title: this.$t('settings.title'),
          icon: 'fas fa-cog'
        },
        {
          href: '/login',
          title: this.$t('login.title'),
          icon: 'fa fa-sign-in-alt'
        }
      ]
    },
    onCollapse (val) {
      // Set the collapsed value so the "collapsed" class will be added to the #app container
      this.collapsed = val
    },
    getUsername () {
      return this.$store.getters.userName
    },
    updateMenu () {
      // Add or remove items from the sidebar based on authentication status
      if (this.isAuthenticated) {
        // User logged in, so remove the log in button
        this.menu.pop()

        // Add the logout button
        this.menu.push({
          href: '/logout',
          title: this.getUsername() + ' - ' + this.$t('sidebar.logout'),
          icon: 'fa fa-sign-out-alt'
        })
      } else {
        // User logged out, so remove the logout button
        this.menu.pop()

        // Add the login button
        this.menu.push({
          href: '/login',
          title: this.$t('login.title'),
          icon: 'fa fa-sign-in-alt'
        })
      }
    },
    addToolsToSidebar () {
      // Request tools to add to sidebar menu
      this.$store.dispatch('getMarketplaceItems')
        .then(({ data }) => {
          // Find "Tools" sidebar item and add the items as its children
          const toolsItem = this._.find(this.menu, { title: this.$t('tools.title') })
          toolsItem.child = data.items.map(item => {
            const href = item.external ? item.path : '/' + item.path
            const alias = item.external ? undefined : href + '/*' // match sub-paths of tools

            // Format marketplace items from API as sidebar items
            // noinspection JSUnresolvedVariable
            return {
              href,
              alias,
              external: item.external,
              title: item.title,
              icon: {
                element: 'img',
                class: 'sidebar-tool-icon',
                attributes: {
                  src: item.icon_url
                }
              }
            }
          })
        })
    }
  },
  mounted () {
    // Add tools to the sidebar
    this.addToolsToSidebar()

    // Check if we have a saved & valid JWT in the local storage, so we can login if it's not expired
    if (typeof localStorage.token !== 'undefined' && localStorage.token !== 'undefined' &&
        typeof localStorage.username !== 'undefined') {
      // Login with JWT token and username
      this.$store.dispatch('loginByJwt', {
        token: localStorage.token,
        username: localStorage.username
      }).then(() => {
        // If we authenticated and we have a page to redirect to in the URL, go to it
        if (this.$store.getters.isAuthenticated && this._.has(this.$route.query, 'redirect')) {
          this.$router.push(this.$route.query.redirect)
        }
      })
    }

    // Check if we have a property for the default route to go to
    if (!this._.isUndefined(this.defaultRoute) && this.defaultRoute.length > 0) {
      // Go to the route
      this.$router.push({
        name: this.defaultRoute
      })
    }
  }
}
</script>

<style>
    #app {
        text-align: center;
    }

    .sidebar-tool-icon {
        width: 30px;
    }
</style>

<style scoped>
    #app {
        padding-left: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    #app.collapsed {
        padding-left: 50px;
    }

    .sidebar-menu {
        z-index: 1;
        width: 250px;
    }

    /* Remove left padding when no sidebar is shown */
    /*noinspection CssUnusedSymbol*/
    #app.no-sidebar {
        padding-left: 0;
    }
</style>
