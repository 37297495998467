<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <img class="swo-logo-img" src="../assets/SWO_wide.png" alt="Social Web Observatory logo">
            </div>
        </div>
        <div class="row text-row">
            <div class="col">
                <h1 class="page-title">The Social Web Observatory</h1>
                <p>
                    The Social Web Observatory is an initiative aiming to help
                    researchers (mainly of the social sciences and digital humanities) to study
                    information diffusion in the social web (news and user generated content - such
                    as comments and posts in social media networks).
                </p>
                <p>
                    The Social Web Observatory project is a joint effort, led by the <a
                    target="_blank" href="https://www.iit.demokritos.gr/group/cakt">Content
                    Analysis and Knowledge Technologies Group</a> of the <a target="_blank"
                    href="https://www.iit.demokritos.gr/skel">Software and Knowledge Engineering
                    Laboratory</a>, with the cooperation of <a target="_blank"
                    href="https://www.scify.gr/">SciFY</a> and <a target="_blank"
                    href="https://ellak.gr/">GFOSS</a>.
                </p>
                <h2>How it works</h2>
                <p>
                    The Social Web Observatory integrates the following subsystems:
                </p>

                <div class="row">
                    <div class="col" v-for="card in cards.sybsystems" :key="card.title">
                        <icon-card :title="card.title"
                                   :text="card.text"
                                   :color="card.color"
                                   :icon-class="card.iconClass"
                                   min-height="405px">
                        </icon-card>
                    </div>
                </div>
                <h2>How to use the Social Web Observatory</h2>
                <p> The Social Web Observatory is free for any use! A user only needs to register
                    with the platform to start using it!
                </p>

                <div class="row">
                    <div class="col" v-for="card in cards.usage" :key="card.title">
                        <icon-card :title="card.title"
                                   :text="card.text"
                                   :color="card.color"
                                   :icon-class="card.iconClass"
                                   :buttons="card.buttons"
                                   min-height="375px">
                        </icon-card>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h2 class="text-left">The Team</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <b-card bg-variant="light" class="main-ncsr-logo-card">
                    <a href="http://www.demokritos.gr/" target="_blank">
                        <img class="main-ncsr-logo"
                             src="../assets/ncsr_logo.png"
                             alt="N.C.S.R &ldquo;Demokritos&rdquo; logo" />
                    </a>
                </b-card>
            </div>

            <!--CAKT people-->
            <div class="col">
                <b-card class="group-card ncsr-card"
                        no-body>
                    <div class="row card-title-row">
                        <div class="col">
                            <a href="https://www.iit.demokritos.gr/group/cakt" target="_blank" class="title-link">
                                <h4 class="card-title-text">CAKT Group - N.C.S.R. &ldquo;Demokritos&rdquo;</h4>
                            </a>
                        </div>
                    </div>
                    <b-list-group flush>
                        <b-list-group-item v-for="person in people.ncsr"
                                           :key="person.name"
                                           class="list-name-item"
                                           :href="person.url">
                            {{ person.name }}
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </div>
        </div>

        <div class="row">
            <!--SciFY people-->
            <div class="offset-3 col-6">
                <b-card class="group-card"
                        no-body>
                    <div class="row card-title-row">
                        <div class="col-2">
                            <img class="title-row-img" src="../assets/scify_logo.png" alt="SciFY logo">
                        </div>
                        <div class="col-8">
                            <a href="http://www.scify.gr/" target="_blank" class="title-link">
                                <h4 class="card-title-text">SciFY</h4>
                            </a>
                        </div>
                    </div>
                    <b-list-group flush>
                        <b-list-group-item v-for="person in people.scify"
                                           :key="person.name" target="_blank"
                                           :href="person.url">
                            {{ person.name }}
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h2 class="text-left">With support from</h2>
            </div>
        </div>
        <div class="row">
            <!--ClarinEL card-->
            <div class="offset-2 col-4">
                <a href="http://www.clarin.gr/" target="_blank">
                    <b-card class="badge-card clarinel-card"
                            no-body>
                        <img class="badge-logo" src="../assets/clarinel_logo.png" alt="clarin:el logo">
                    </b-card>
                </a>
            </div>

            <!--Apollonis card-->
            <div class="col-4">
                <a href="https://apollonis-infrastructure.gr/" target="_blank">
                    <b-card class="badge-card apollonis-card"
                            no-body>
                        <img class="badge-logo" src="../assets/apollonis_logo.png" alt="Apollonis logo">
                    </b-card>
                </a>
            </div>
        </div>
        <div class="row">
            <!--SciFY card-->
            <div class="offset-2 col-4">
                <a href="http://www.scify.gr/" target="_blank">
                    <b-card class="badge-card scify-card"
                            no-body>
                        <div class="scify-img-wrapper">
                            <img src="../assets/scify_logo.png" alt="SciFY logo">
                        </div>
                    </b-card>
                </a>
            </div>

            <!--SciFY card-->
            <div class="col-4">
                <a href="https://ellak.gr/" target="_blank">
                    <b-card class="badge-card gfoss-card"
                            no-body>
                        <img class="badge-logo" src="../assets/ellak_logo.png" alt="GFOSS logo">
                    </b-card>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import IconCard from '../components/IconCard'

export default {
  name: 'About',
  components: { IconCard },
  data () {
    return {
      people: {
        ncsr: [
          {
            name: 'Vangelis Karkaletsis',
            url: 'http://karkaletsis.iit.demokritos.gr/'
          },
          {
            name: 'Georgios Petasis',
            url: 'http://www.ellogon.org/petasis/'
          },
          {
            name: 'George Giannakopoulos',
            url: 'http://users.iit.demokritos.gr/~ggianna/'
          },
          {
            name: 'Leonidas Tsekouras',
            url: ''
          },
          {
            name: 'George Roussos',
            url: ''
          }
        ],
        scify: [
          {
            name: 'Aris Kosmopoulos',
            url: ''
          },
          {
            name: 'Alexandros Tzoumas',
            url: ''
          },
          {
            name: 'George Giannakopoulos',
            url: 'http://users.iit.demokritos.gr/~ggianna/'
          }
        ]
      },
      cards: {
        sybsystems: [
          {
            iconClass: 'fas fa-assistive-listening-systems',
            title: 'Listen',
            color: '#111C25',
            text: 'The Social Web Observatory listens a wide variety of news sources (more than ' +
                '1300 RSS sources) and user generated content (such as comments in ' +
                '<a target="_blank" href="https://disqus.com/">DISQUS</a> and tweets in ' +
                '<a target="_blank" href="https://twitter.com/">Twitter</a>).'
          },
          {
            iconClass: 'fas fa-search',
            title: 'Search',
            color: '#234C58',
            text: 'Content is indexed through a search infrastructure, enabling users to retrieve ' +
                'context through sets of <em>keywords</em>, for further analysis.'
          },
          {
            iconClass: 'fas fa-microscope',
            title: 'Analyse',
            color: '#3887A9',
            text: 'Content retrieved through keyword search is analysed along various dimensions to ' +
                'extract indicators such as trends, coverage, events, sentiment, stance, etc.'
          },
          {
            iconClass: 'fas fa-chart-line',
            title: 'Analytics',
            color: '#4DACBE',
            text: 'Both context and indicators are visualised through predefined dashboards and other ' +
                'analytics tools, to provide information and insights on the various issues defined by ' +
                'keyword searches.'
          }
        ],
        usage: [
          {
            iconClass: 'fas fa-user-plus',
            title: 'Sign up',
            // color: '#AA9F39',
            text: 'A user can sign up to the Social Web Observatory, or use her/his academic login!',
            buttons: [
              {
                label: 'Sign up',
                to: 'signup',
                variant: 'warning'
              }
            ]
          },
          {
            iconClass: 'fas fa-tachometer-alt',
            title: 'Define a dashboard',
            // color: '#88A236',
            text: 'Registered users are allowed to create an unlimited number of <em>Dashboards</em>. ' +
                'Each Dashboard is defined through a set of positive and negative keywords. (A negative ' +
                'keyword is a keyword that must not exist in the content retrieved with the positive ' +
                'keywords).'
          },
          {
            iconClass: 'fas fa-eye',
            title: 'View Analytics',
            // color: '#2D882D',
            text: 'A defined Dashboard will be populated with results on roughly a daily cycle (i.e. ' +
                'each morning). The user can view and analyse the provided analytics and visualisations.'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
    h1.page-title {
        text-align: center;
    }

    .swo-logo-img {
        border-radius: 15px;
        height: 185px;
    }

    .text-row {
        text-align: justify;
    }

    .row {
        margin-bottom: 20px;
    }

    .main-ncsr-logo {
        height: 100%;
    }

    .main-ncsr-logo-card {
        height: 100%;
    }

    .ncsr-card {
        background-size: auto 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("http://www.demokritos.gr/wp-content/themes/demokritos/images/logo/logo.jpg");
    }

    .group-card {
        padding-top: 0;
    }

    .card-title-row {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .card-title-row {
        background-color: rgba(255, 255, 255, 0.95);
    }

    .list-name-item {
        background-color: rgba(255, 255, 255, 0.85);
    }

    /*noinspection CssUnusedSymbol*/
    .list-name-item.list-group-item-action:hover {
        background-color: rgba(240, 240, 240, 0.85);
    }

    .title-link {
        text-decoration: none;
    }

    img.badge-logo {
        width: 100%;
    }

    .badge-card {
        padding: 20px;
        height: 100%;
    }

    .apollonis-card {
        background-color: #3E5062;
    }

    .apollonis-card:hover {
        background-color: #4a6175;
    }

    /*.gfoss-card {*/
    /*    background-color: #ffce9b;*/
    /*}*/

    .gfoss-card:hover {
        background-color: rgba(255, 143, 0, 0.16);
    }

    .clarinel-card:hover {
        background-color: rgb(255, 230, 194);
    }

    .scify-img-wrapper > img {
        height: 125px;
    }

    .scify-card:hover {
        background: linear-gradient(to bottom right, rgba(255, 153, 0, 0.45) 0%, rgba(153, 255, 153, 0.46) 100%);
    }

    .card-title-text {
        color: rgb(33, 37, 41);
        margin-bottom: 0;
    }

    .title-row-img {
        height: 28px;
    }
</style>
