<template>
    <multiselect v-model="value"
                 @input="updateName"
                 :options="options"
                 :loading="isLoading"
                 :searchable="true"
                 :internal-search="false"
                 @search-change="searchName"
                 placeholder="Search name">
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'NameInput',
  components: { Multiselect },
  props: {
    value: String
  },
  data () {
    return {
      name: '',
      options: [], // Options for the list
      isLoading: false
    }
  },
  methods: {
    searchName (name) {
      // Get name suggestions from Elasticsearch
      this.isLoading = true
      this.$store.dispatch('suggestNames', name)
        .then(response => {
          this.options = response.data.names
          this.isLoading = false
        })
    },
    updateName () {
      // Name changed event
      this.$emit('input', this.value)
    }
  }
}
</script>

<style scoped>

</style>
