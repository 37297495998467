// Define colors
const colorsList = ['#DBF3AB', '#B3E8FD', '#ABE3DD', '#9999FF', '#DBA9A9', '#D9A5F9', '#FF9999', '#FFE5EB', '#FFDB99',
  '#99FF99', '#FFFF99', '#E5E599', '#E5E5FF', '#E5E5E5', '#CC9999', '#99CC99', '#9999CC', '#CCCC99', '#CC99CC',
  '#99CCCC', '#CCCCCC', '#E59999', '#99E599', '#9999E5', '#E5E599', '#E599E5', '#99E5E5', '#E5E5E5', '#E5F1E5',
  '#DBE9F9', '#99C1C3', '#FFFFD6', '#D9D9DA', '#FFEAC1', '#EAC199', '#D6C199', '#C1EAB1', '#99C1D6', '#FFD699',
  '#DBA9A9', '#9999FF', '#B2F2EC', '#BFD8D9', '#FFCBB9', '#E4E2C3', '#FF9999', '#CAFD99', '#F9FFF9', '#CCCCCC',
  '#FFDB99', '#A3A3C5', '#EFE5EF', '#DEFFAB', '#999999', '#99E5FF', '#C3C3C3', '#FFD199', '#FCFCFF', '#B3C3F3',
  '#E8A1CE', '#EDEDED', '#CC99CC', '#FFF1DE', '#FDCCC6', '#B5ECEA', '#B799CD', '#EDE1D1', '#99FFFF', '#99C199',
  '#99FFCB', '#FEFBF5', '#CC9999', '#C8CFD6', '#CBFFED', '#F6D5C9', '#A6D0A6', '#D0B4A0', '#E4D2D2', '#FF99FF',
  '#A5D2FF', '#9999CC', '#F8F5DD', '#F9F5D1', '#FBF1E0', '#ABD0BB', '#E3BBED', '#FFE1E6', '#BBC3AB', '#B1E0C6',
  '#E5E5E5', '#DFE7F1', '#FFC3E1', '#D09999', '#F9FFFF', '#FBFBFB', '#99CC99', '#F1A1B1', '#B5B1D0', '#EBCEB2',
  '#FFF8EE', '#FFFFF2', '#FFF4E1', '#C1EBDD', '#99CCCC', '#D9B9AB', '#EBBDBD', '#FFEF99', '#ABB8B8', '#CCCC99',
  '#CFEBFD', '#D099D0', '#F0C5D3', '#C3D1A7', '#FBFFFD', '#DEEFF5', '#E2E19D', '#FFFBF8', '#DFF2F5', '#F0C5EE',
  '#9999EB', '#F9CCCC', '#9999D0', '#DFF8F8', '#D6ADEA', '#FF99FF', '#FFF7EB', '#99FDD6', '#FFFCF1', '#DCDCDC',
  '#F1D9F1', '#F9FCFF', '#CBFF99', '#FFFDFD', '#FFB499', '#FFA1D3', '#C3BDEB', '#F5F5FD', '#FFFFF9', '#E0A6A6',
  '#FFD9C9', '#FDFDED', '#EDC3A5', '#F2FFFF', '#C5CCD2', '#F1F1F1', '#99D0D0', '#F0DBA5', '#FADABF', '#FFF4F3',
  '#F1E2CF', '#ADEBAD', '#FDF7EF', '#FFFFFF', '#FFFDF9', '#FFE5EB', '#FFF0E3', '#CAC2F8', '#A5E0DD', '#F8CDF8',
  '#D0AAF3', '#99EBEC', '#D2E4D2', '#DCEBAD', '#B5CDE1', '#D499ED', '#FDF9F5', '#FFF9FB', '#FFFDEB', '#99FFFF',
  '#D3C5F0', '#D2F8D2', '#FFF4E7', '#C1D4F7', '#D5FDD5', '#FFFF99', '#FFC0B5', '#FBFBF1', '#CFEBF7', '#99FF99',
  '#F9FCFF', '#FDF7EF', '#99FFFF', '#CBFFED', '#F9FFFF', '#FBFBF1', '#FFF4E7', '#999999', '#FFF7EB', '#9999FF',
  '#D0AAF3', '#DBA9A9', '#F1E2CF', '#BFD8D9', '#CBFF99', '#EDC3A5', '#FFCBB9', '#C1D4F7', '#FFFCF1', '#F1A1B1']

export default {
  install: function (Vue, options) {
    // 3. inject some component options
    Vue.mixin({
      data () {
        return {
          get colors () {
            return colorsList
          }
        }
      }
    })
  }
}
