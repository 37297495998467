<template>
  <div>
    <!--Timeout alert-->
    <b-alert v-if="showError" class="retry-alert" show variant="warning">
      <b-row>
        <b-col>
          <p>{{ $t('components.event_word_cloud.error_msg') }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-btn @click="updateChart" variant="warning">
            <i class="fas fa-redo"></i>
            {{ $t('components.event_word_cloud.try_again_btn') }}
          </b-btn>
        </b-col>
      </b-row>
    </b-alert>

    <!--Word cloud-->
    <highcharts :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
export default {
  name: 'EventWordCloud',
  props: {
    eventId: String
  },
  data () {
    return {
      showError: false,
      chartOptions: {
        chart: {
          type: 'wordcloud',
          events: {
            load () {
              // Show loading when the chart loads
              this.showLoading()
            }
          }
        },
        title: {
          text: null
        },
        series: []
      }
    }
  },
  methods: {
    updateChart: function () {
      // Get the word cloud data
      this.$store.dispatch('getWordCloud', this.eventId)
        .then(({ data }) => {
          this.chartOptions.series = [{
            type: 'wordcloud',
            data: data.data,
            name: 'Weight',
            minFontSize: 5
          }]

          // Hide chart loading and error
          this.$refs.chart.chart.hideLoading()
          this.showError = false
        }, () => {
          // Show error message with try again button
          this.showError = true
        })
    }
  },
  watch: {
    dateRange: function (newRange) {
      // Date range changed, update the chart
      this.updateChart()
    }
  },
  mounted () {
    this.updateChart()
  }
}
</script>

<style scoped>
.retry-alert {
  position: absolute;
  top: 150px;
  z-index: 100;
  width: 466px;
}
</style>
