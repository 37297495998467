<template>
    <div>
        <!--Title-->
        <b-row>
            <b-col>
                <h2>{{ $t('organize_sources.title_edit_group') }}</h2>
            </b-col>
        </b-row>

        <!--Source group editor-->
        <b-row>
            <b-col>
                <source-group-editor :group-id="id"/>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import SourceGroupEditor from '../../components/SourceGroupEditor'

export default {
  name: 'EditSourceGroup.vue',
  components: { SourceGroupEditor },
  props: {
    id: String
  }
}
</script>

<style scoped>

</style>
