<template>
    <span>
        <!--Twitter button-->
        <a :href="urls.twitter"
           target="_blank"
           title="Twitter"
           :class="{ 'not-active': urls.twitter.length <= 0 }"
           class="social-link">
            <b-badge variant="info" class="social-badge-btn">
                <i class="fab fa-twitter"></i>
            </b-badge>
        </a>

        <!--Instagram button-->
        <a :href="urls.instagram"
           target="_blank"
           title="Instagram"
           :class="{ 'not-active': urls.instagram.length <= 0 }"
           class="social-link">
            <b-badge variant="info" class="social-badge-btn instagram-badge">
                <i class="fab fa-instagram"></i>
            </b-badge>
        </a>

        <!--Facebook button-->
        <a :href="entityData['facebook_page']"
           target="_blank"
           title="Facebook"
           :class="{ 'not-active': entityData['facebook_page'] === undefined || entityData['facebook_page'] === null || entityData.facebook_page.length <= 0 }"
           class="social-link">
            <b-badge variant="primary" class="social-badge-btn">
                <i class="fab fa-facebook-f"></i>
            </b-badge>
        </a>

        <!--Wikipedia button-->
        <a :href="entityData['wikipedia_url']"
           target="_blank"
           title="Wikipedia"
           :class="{ 'not-active': entityData['wikipedia_url'] === undefined || entityData['wikipedia_url'] === null || entityData.wikipedia_url.length <= 0 }"
           class="social-link">
            <b-badge variant="light" class="social-badge-btn smaller-badge-padding">
                <i class="fab fa-wikipedia-w"></i>
            </b-badge>
        </a>

        <!--YouTube button-->
        <a :href="entityData['youtube_channel']"
           target="_blank"
           title="YouTube Channel"
           :class="{ 'not-active': entityData['youtube_channel'] === undefined || entityData['youtube_channel'] === null || entityData.youtube_channel.length <= 0 }"
           class="social-link">
            <b-badge variant="danger" class="social-badge-btn smaller-badge-padding">
                <i class="fab fa-youtube"></i>
            </b-badge>
        </a>

        <!--Homepage button-->
        <a :href="entityData['homepage']"
           target="_blank"
           title="Homepage"
           :class="{ 'not-active': entityData['homepage'] === undefined || entityData['homepage'] === null || entityData.homepage.length <= 0 }"
           class="social-link">
            <b-badge variant="primary" class="social-badge-btn smaller-badge-padding">
                <i class="fas fa-globe"></i>
            </b-badge>
        </a>
    </span>
</template>

<script>
export default {
  name: 'EntitySocialButtons',
  props: {
    entityData: Object
  },
  data () {
    return {
      urls: {
        twitter: '',
        instagram: ''
      },
      customUrls: [
        ['twitter_username', 'https://twitter.com/', 'twitter'],
        ['instagram_username', 'https://www.instagram.com/', 'instagram']
      ]
    }
  },
  mounted () {
    this._.each(this.customUrls, (urlData) => {
      const key = urlData[0]
      const url = urlData[1]
      const urlsKey = urlData[2]

      // If there is a Twitter username for the entity, create the Twitter URL
      if (this._.has(this.entityData, key) && !this._.isNull(this.entityData[key]) &&
          this.entityData[key].length > 0) {
        // Get the username
        let username = this.entityData[key]

        // Remove "@" from start of username, if it exists
        if (username.startsWith('@')) {
          username = username.substring(1)
        }

        // Create the URL
        this.urls[urlsKey] = url + username
      }
    })
  }
}
</script>

<style scoped>
    .social-badge-btn {
        width: 22px;
        height: 19px;
        margin-right: 2px;
    }

    .social-link {
        margin-right: 2px;
        opacity: 0.65;
    }

    .social-link:hover {
        opacity: 1.0;
    }

    .smaller-badge-padding {
        padding-left: 2px;
        padding-right: 2px;
    }

    .instagram-badge {
        background: #e95950;
    }

    .not-active {
        pointer-events: none;
        cursor: default;
        opacity: 0.2;
        filter: saturate(0);
    }
</style>
