<template>
    <b-card class="table-card">
        <!--Title & Filter row-->
        <div class="row title-row">
            <!--Title-->
            <div class="col">
                <h3>{{ $t('components.data_sources.title') }}</h3>
            </div>

            <!--Filter-->
            <div class="col">
                <div class="row">
                    <div class="col-4 filter-text-col">
                        <i class="fas fa-filter"></i> {{ $t('components.data_sources.filter') }}
                    </div>
                    <div class="col-8">
                        <b-input v-model="filter"></b-input>
                    </div>
                </div>
            </div>
        </div>

        <!--Table-->
        <div class="row table-row">
            <div class="col">
                <b-table hover
                         head-variant="dark"
                         :small="true"
                         :fields="fields"
                         :filter="filter"
                         :per-page="pageSize"
                         :current-page="currentPage"
                         :items="items">
                    <!--Custom rendering of sources as links-->
                    <template v-slot:cell(source)="data">
                        <a :href="'http://' + data.item.source" target="_blank">
                            {{ data.item.source }}
                        </a>
                    </template>
                </b-table>
            </div>
        </div>

        <!--Pagination-->
        <div class="row" v-if="items.length > pageSize">
            <div class="col pagination-col">
                <!--suppress HtmlDeprecatedAttribute -->
                <b-pagination size="sm" align="center"
                              :total-rows="items.length"
                              v-model="currentPage"
                              :per-page="pageSize"/>
            </div>
        </div>
    </b-card>
</template>

<script>
export default {
  name: 'DataSourcesTable',
  props: {
    // entityId: String,
    dateRange: Object
  },
  data () {
    return {
      items: [],
      fields: [
        {
          key: 'source',
          label: this.$t('components.data_sources.source'),
          sortable: true
        },
        {
          key: 'count',
          label: this.$t('components.data_sources.count'),
          sortable: true
        }
      ],
      pageSize: 15,
      currentPage: 1,
      filter: null
    }
  },
  methods: {
    updateChart: function () {
      // Create request parameters
      const requestData = {
        // entity_id: this.entityId,
        date_start: this.dateRange.start,
        date_end: this.dateRange.end,
        index: 'articles',
        field: 'netloc',
        size: 1000
      }

      this.$store.dispatch('chartTermsAggregation', requestData)
        .then(({ data }) => {
          this.items = this._.map(data.terms, term => {
            return {
              source: term[0],
              count: term[1]
            }
          })
        })
    }
  },
  watch: {
    dateRange: function () {
      // Date range changed, update the chart
      this.updateChart()
    }
  },
  mounted () {
    this.updateChart()
  }
}
</script>

<style scoped>
    .table-card {
        min-height: 670px;
    }

    /*noinspection CssUnusedSymbol*/
    .table-card > .card-body {
        text-align: left;
    }

    .table-row {
        margin-left: -20px;
        margin-right: -20px;
    }

    .table-row > .col {
        padding-right: 0;
        padding-left: 0;
    }

    .filter-text-col {
        text-align: right;
        padding-right: 0;
    }

    /* Add space below title */
    .title-row {
        margin-bottom: 10px;
    }

    /* Remove bottom margin from pagination component */
    /*noinspection CssUnusedSymbol*/
    .b-pagination {
        margin-bottom: 0;
    }
</style>
