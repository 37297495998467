<template>
    <b-card :title="title"
            bg-variant="light"
            class="single-value-container">
        <h1>
            <i v-if="icon" :class="icon"></i>
            {{ value }}
        </h1>
    </b-card>
</template>

<script>
import BaseSingleValueDisplay from './BaseSingleValueDisplay.vue'

export default {
  name: 'MetricDisplay',
  extends: BaseSingleValueDisplay,
  props: {
    icon: String
  },
  methods: {
    updateValue: function () {
      // Gather request parameters
      const requestParams = {
        entity_id: this.entityId,
        type: this.type,
        date_start: this.dateRange.start,
        date_end: this.dateRange.end
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestParams, this.filters)
      }

      // Get the average polarity from the API
      this.$store.dispatch('chartItemsCount', requestParams)
        .then(({ data }) => {
          this.value = data.value
        })
    }
  }
}
</script>

<style scoped>

</style>
