<template>
    <highcharts :options="chartOptions"></highcharts>
</template>

<script>
export default {
  name: 'OverviewTimeline',
  props: {
    dateRange: Object,
    type: String,
    typeLabel: String
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        series: [],
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'Count'
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical'
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        tooltip: {
          shared: true
        },
        navigator: {
          enabled: true,
          series: {
            type: 'column',
            stacking: 'normal'
          }
        }
      }
    }
  },
  methods: {
    updateChart: function () {
      // Make the request
      this.$store.dispatch('chartOverviewTimeline', {
        type: this.type,
        params: {
          date_start: this.dateRange.start,
          date_end: this.dateRange.end
        }
      })
        .then(({ data }) => {
          // Remove old series
          this.chartOptions.series = []

          // Create a series for each item type
          this._.each(data.items, (feedData, feedName) => {
            // Create the series object
            const newSeries = {
              name: feedName,
              data: feedData,
              showInNavigator: true
            }

            // Add the series to the chart
            this.chartOptions.series.push(newSeries)
          })
        })
    },
    setChartTitle: function () {
      this.chartOptions.title.text = this.typeLabel + ' ' + this.$t('components.overview_timeline.timeline') + (this.type === 'tweets' ? '' : ' (' + this.$t('components.overview_timeline.per_type') + ')')
    }
  },
  watch: {
    dateRange: function () {
      // Date range changed, update the chart
      this.updateChart()
    },
    '$i18n.locale': function () {
      // Update chart title when locale changes
      this.setChartTitle()
    }
  },
  mounted () {
    this.updateChart()
    this.setChartTitle()
  }
}
</script>

<style scoped>

</style>
