import Vue from 'vue'

export const EventBus = new Vue()

export function isValidJwt (jwt) {
  if (!jwt || jwt.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
  const now = new Date()
  return now < exp
}

/**
 * Parse a JWT and return the username ("sub" field).
 * @param jwt
 * @returns username of user
 */
export function usernameFromJwt (jwt) {
  if (!jwt || jwt.split('.').length < 3) {
    return null
  }
  const data = JSON.parse(atob(jwt.split('.')[1]))
  return data.sub
}
