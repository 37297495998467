<template>
  <b-row class="container">
    <b-col offset="2" cols="4">
      <span class="flag-container"
            :class="{ 'selected-flag': $i18n.locale === 'el' }"
            @click="switchLanguage('el')">
        <country-flag country="gr"></country-flag>
      </span>
    </b-col>
    <b-col cols="4">
      <span class="flag-container"
            :class="{ 'selected-flag': $i18n.locale === 'en' }"
            @click="switchLanguage('en')">
        <country-flag country="gb"></country-flag>
      </span>
    </b-col>
  </b-row>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'LanguageSwitcher',
  components: {
    CountryFlag
  },
  methods: {
    switchLanguage (newLang) {
      this.$i18n.locale = newLang
      localStorage.lang = newLang
    }
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  margin: 5px 0;
}

.flag-container {
  cursor: pointer;
  opacity: 0.5;
}

.flag-container:hover {
  opacity: 1.0;
}

/*noinspection CssUnusedSymbol*/
.selected-flag {
  opacity: 1.0;
}
</style>
