import { render, staticRenderFns } from "./SourceGroupSelector.vue?vue&type=template&id=0d8270bf&scoped=true&"
import script from "./SourceGroupSelector.vue?vue&type=script&lang=js&"
export * from "./SourceGroupSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d8270bf",
  null
  
)

export default component.exports