<template>
    <b-row>
        <!--Field label-->
        <b-col md="3" class="text-bold">
            {{ field.label }}
        </b-col>

        <!--Field values for each type - text-->
        <b-col v-if="field.type === 'text'">
            {{ entity[field.key] }}
        </b-col>

        <!--Date-->
        <b-col v-if="field.type === 'timestamp'">
            {{ new Date(entity[field.key]).toLocaleString() }}
        </b-col>

        <!--List of strings-->
        <b-col v-if="field.type === 'list'">
            <b-row>
                <!--List of the keywords-->
                <b-col>
                    <span class="keyword" v-for="item in entity[field.key]" :key="item">
                        <b-badge>{{ item }}</b-badge>
                    </span>
                </b-col>

                <!--Copy to clipboard button-->
                <b-col v-if="entity[field.key].length > 0" class="copy-btn-col">
                    <b-button size="sm"
                              v-b-tooltip.hover.d500=""
                              title="Copy keywords to clipboard."
                              variant="outline-secondary"
                              v-clipboard:copy="listToCopyableString(entity[field.key])"
                              v-clipboard:success="onCopySuccess"
                              v-clipboard:error="onCopyError">
                        <i class="fas fa-copy"></i>
                    </b-button>
                </b-col>
            </b-row>
        </b-col>

        <!--Boolean-->
        <b-col v-if="field.type === 'boolean'">
            <b-checkbox v-model="entity[field.key]"
                        :disabled="true">
            </b-checkbox>
        </b-col>

        <!--URL (link)-->
        <b-col v-if="field.type === 'url'" class="url-col">
            <a :href="entity[field.key]" target="_blank">{{ decodeUrl(entity[field.key]) }}</a>
        </b-col>
    </b-row>
</template>

<script>

export default {
  name: 'EntityField',
  components: {},
  props: {
    field: Object,
    entity: Object
  },
  methods: {
    decodeUrl: function (url) {
      // If there is a non-empty string, decode it
      if (this._.isString(url) && url.length > 0) {
        return decodeURIComponent(url)
      } else {
        // Return the URL as is, because decoding an empty string shows "null"
        return url
      }
    },
    listToCopyableString: function (itemsList) {
      // Join items with unicode record separator character
      return itemsList.join(this.$store.getters.keywordSeparator)
    },
    onCopySuccess: function () {
      // Show success message to user
      this.$bvToast.toast('Items copied successfully!', {
        title: 'Copy Success',
        autoHideDelay: 2000
      })
    },
    onCopyError: function () {
      // Show error message to user
      this.$bvToast.toast('Could not copy items :(', {
        title: 'Copy Error',
        autoHideDelay: 2000
      })
    }
  }
}
</script>

<style scoped>
    .text-bold {
        font-weight: bold;
    }

    .url-col {
        word-break: break-word;
    }

    .copy-btn-col {
        max-width: 50px;
    }

    span.keyword {
        margin-right: 2px;
    }

    span.keyword:last-child {
        margin-right: 0;
    }
</style>
