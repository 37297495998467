<template>
    <highcharts :options="chartOptions"></highcharts>
</template>

<script>
// noinspection JSUnusedLocalSymbols
export default {
  name: 'AllVsEntity',
  props: {
    entityId: String,
    dateRange: Object,
    type: String,
    title: String,
    filters: Object
  },
  data () {
    // noinspection JSUnresolvedFunction
    return {
      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        series: [],
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.type[0].toUpperCase() + this.type.substring(1) + 's'
          },
          type: 'logarithmic'
        },
        tooltip: {
          shared: true
        },
        navigator: {
          enabled: true,
          xAxis: {},
          yAxis: {
            type: 'logarithmic'
          }
        }
      }
    }
  },
  methods: {
    updateChart: function () {
      // Create request parameters
      const requestParams = {
        date_start: this.dateRange.start,
        date_end: this.dateRange.end,
        type: this.type,
        entity_id: this.entityId
      }

      // Add extra filters to the request if they exist
      if (!this._.isUndefined(this.filters)) {
        this._.assign(requestParams, this.filters)
      }

      // Get data from API and display it
      this.$store.dispatch('chartAllVsEntity', requestParams)
        .then(({ data }) => {
          // Set the new series to the chart
          this.chartOptions.series = data.series

          // Set the chart's start/end X axis values
          const max = new Date(requestParams.date_end)
          const min = new Date(requestParams.date_start)
          min.setHours(0, 0, 0, 0)

          this.chartOptions.xAxis.min = this.chartOptions.navigator.xAxis.min = min.getTime()
          this.chartOptions.xAxis.max = this.chartOptions.navigator.xAxis.max = max.getTime()
        })
    },
    setChartTitle: function () {
      this.chartOptions.title.text = this.title || (this.$t('components.all_vs_entity.default_title_start') + ' ' + this.type + this.$t('components.all_vs_entity.default_title_end'))
    }
  },
  watch: {
    dateRange: function (newRange) {
      // Date range changed, update the chart
      this.updateChart()
    },
    filters: function () {
      // Filters changed, update the chart
      this.updateChart()
    },
    '$i18n.locale': function () {
      // Update chart title when locale changes
      this.setChartTitle()
    }
  },
  mounted () {
    this.updateChart()
    this.setChartTitle()
  }
}
</script>

<style scoped>

</style>
